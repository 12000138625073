.header_collapse {
    padding: 8px 0 0;

    .collapse_item {       
        color: rgba(255, 255, 255, 0.3);

        + .collapse_item {
            margin-top: 8px;
        }

        .collapse_link {
            text-decoration: none;
        }
    }
}