/* ==========================================================================
  Geral
========================================================================== */

body {
  font-family: 'margem-rounded', sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #19191E;
  background-color: #FFFFFF;
  transition: 0.4s ease;

  &.dark { 
    background-color: $preto;
  }
}

.container {
  @media(max-width: 991px){
    padding-left: 24px;
    padding-right: 24px;
  }
}

.marcas-simbolo{
  text-align: center;
  padding-bottom: 89px;

  @media(min-width: 992px){
    padding-bottom: 114px;
  }

  .marcas{
    
    @media (min-width: 768px){
      display: flex;
      padding-left: 8px;
      padding-right: 8px;
      column-gap: 6px;
    }
  }

  .subtitulo{
    margin-top: 16px;
    margin-bottom: 32px;
    color: $laranja;
  }
  
  .marca{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 320px;
    overflow: hidden;

    @media (min-width: 768px){
      flex: 1;
      height: 550px;
    }

    .bg{
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }

    .video{
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 1;
      min-width: 100%;
      min-height: 100%;
      max-width: inherit;
      max-height: inherit;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: all ease 0.8s;
    }

    .info{
      position: relative;
      z-index: 3;
      max-width: 90%;

      @media (min-width: 768px){
        max-width: 390px;
      }
    }

    .logo{
      transform: translateY(70px) scale(1);
      transition: all ease 0.5s;

      @media (min-width: 992px){
        transform: translateY(80px) scale(1);
      }
    }

    .texto,
    .link{
      display: inline-block;
      transform: translateY(100px);
      opacity: 0;
      transition-property: all;
      transition-duration: 0.5s;
    }

    .texto{
      margin-top: 24px;
      margin-bottom: 24px;
      font-size: 14px;
      line-height: normal;
      letter-spacing: -0.14px;
      transition-delay: 0.1s;

      @media (min-width: 992px){
        margin-top: 32px;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.16px;
      }
    }

    .link{
      color: #0045B5;
      line-height: 20px;
      text-decoration: none;
      transition: all ease 0.4s;

      &::after{
        content: '';
        display: block;
        margin-top: 4px;
        width: 100%;
        height: 2px;
        background: linear-gradient(90deg, #0045B5 0%, rgba(255, 255, 255, 0.00) 100%);
      }
    }

    &:hover,
    &:focus{
      .video{
        opacity: 0.1;
      }

      .logo{
        transform: translateY(0) scale(0.9);
      }

      .texto,
      .link{
        transform: translateY(0);
        opacity: 1;
      }

      .texto{
        transition-delay: 0.05s;
      }

      .link{
        transition-delay: 0.1s;
      }
    }

    &.lubrificantes{

      .flutuante-1,
      .flutuante-2,
      .flutuante-3{
        position: absolute;
        top: -30px;
        left: 0;
        scale: 0.6;
        transform-origin: top left;
        transition-property: all;
        transition-timing-function: cubic-bezier(0.68, 0.09, 0.36, 0.91);
        transition-duration: 0.4s;

        @media (min-width: 768px){
          top: 0;
          scale: 1;
        }
      }

      .flutuante-1{
        z-index: 4;
      }

      .flutuante-2{
        z-index: 3;
        transition-delay: 0.05s;
      }

      .flutuante-3{
        z-index: 2;
        transition-delay: 0.1s;
      }

      .bg{
        background-color: $amarelo;
      }

      .video{
        mix-blend-mode: multiply;
      }

      .link{
        color: #0045B5;

        &::after{
          background: linear-gradient(90deg, #0045B5 0%, rgba(255, 255, 255, 0.00) 100%);
        }
      }

      .logo{
        max-width: 250px;

        @media (min-width: 768px){
          max-width: initial;
        }
      }

      &:hover,
      &:focus{
        .flutuante-1,
        .flutuante-2,
        .flutuante-3{
          left: -30px;

          @media (min-width: 768px){
            left: -50px;
          }
        }
      }
    }

    &.texaco{

      .bg{
        background-color: $preto;
      }

      .texto{
        color: white;
      }

      .link{
        color: #FD000D;

        &::after{
          background: linear-gradient(90deg, #FD000D 0%, rgba(255, 255, 255, 0.00) 100%);
        }
      }
    }
  }

  &--pagina{
    padding-bottom: 0;

    .marcas{
      padding: 8px;

      .marca + .marca{
        margin-top: 8px;

        @media (min-width: 768px){
          margin-top: 0;
        }
      }
    }
  }
}

.banner {
  width: 100%;
  padding-top: 160px;
  height: 678px;
  position: relative;
  z-index: 1;
  background-color: black;
  overflow: hidden;
  
  @media (min-width: 992px) {
    display: flex;
    align-items: center;
    padding-top: 0;
    height: 567px;
  }   

  @media(min-width: 1200px){
    height: calc(100vh - 122.73px);
    max-height: 660px;
    min-height: 400px;
  }

  .bg-video {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    max-width: initial;
    object-fit: cover;
    z-index: -1;
    
    video{
      min-width: 100%;
      min-height: 100%;
    }
  }

  .container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    
    @media(max-width: 991px) {
      padding-left: 56px;
    }
  }

  .texto-banner {
    display: inline-block;
    color: #FFF;
    font-size: 16px;
    font-weight: 500;
    margin-top: 32px;
    margin-bottom: 0;
    text-decoration: none;
    transition: 0.3s ease;

    background-color: transparent;
    outline: none;
    border: 0;

    &:hover {
      color: $laranja;
    }

    &::after {
      content: "";
      display: block;
      margin-top: 4px;
      width: 100%;
      height: 2px;
      background: linear-gradient(90deg, #FF5F19 0%, rgba(255, 255, 255, 0.00) 100%);
    }
  }

  @keyframes moveUpDown {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(3px);
    }
  }

  .box-chevron {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 29px;
    position: absolute;
    background-color: $laranja;
    bottom: 0;
    right: 50%;
    transform: translateX(50%);
    text-decoration: none;
    color: inherit;

    .chevron-down {
      animation: moveUpDown 1s ease infinite;
    }
  }

}

.sect_transformar {
  position: relative;
  background-color: $cinza-escuro;
  width: 100%;
  overflow: hidden;


  @media (min-width: 992px) {
    height: 690px;
    display: flex;
    align-items: center;
  }

  .grafismo-laranja {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 189px;
    background-color: white;
    background-image: url('../img/grafismo-laranja-mobile.png');
    background-position: center -14px;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: $laranja;

    @media (min-width: 992px) {
      width: 44%;
      height: 690px;
      background-image: url('../img/grafismo-laranja-2.png');
      background-size: unset;
      background-position: 50% 50%;
    }
  }

  .box-laranja {
    background-color: $laranja;
    padding: 57px 0;
    position: relative;
    z-index: 2;
    margin-top: 57px;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      width: 100vw;
      height: 100%;
      background-color: inherit;
      transform: translateX(-50%);
      z-index: -1; 

      @media (min-width: 992px) {
        content: none;
      }
      
    }

    @media (min-width: 992px) {
      padding: 47px 49px;
      margin-top: 42px;
    }

  }

  .box-cinza {
    background-color: $cinza;
    padding: 57px 0;
    position: relative;
    z-index: 2;
    margin-top: 77px;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      width: 100vw;
      height: 100%;
      background-color: inherit;
      transform: translateX(-50%);
      z-index: -1; 

      @media (min-width: 992px) {
        content: none;
      }
      
    }

    @media (min-width: 992px) {
      padding: 47px 49px;
      margin-top: 42px;
    }

  }

  .texto-transformar {
    color: white;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.16px;
    margin-bottom: 0;
    padding: 40px 0;

    @media (min-width: 992px) {
      font-size: 16px;
      padding: 0;
    }
  }
}


.sect_lubrin-exelencia{
  position: relative;
  background-color: #19191E;
  width: 100%;
  overflow: hidden;


  @media (min-width: 992px) {
    height: 475px;
    display: flex;
    align-items: center;
  }

  .grafismo-laranja {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 189px;
    background-color: white;
    background-image: url('../img/grafismo-laranja-mobile.png');
    background-position: center -14px;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: $laranja;

    @media (min-width: 992px) {
      width: 44%;
      height: 690px;
      background-image: url('../img/grafismo-laranja-lubrin.png');
    }
  }

  .box-laranja {
    background-color: $laranja;
    padding: 57px 0;
    position: relative;
    z-index: 2;
    margin-top: 57px;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      width: 100vw;
      height: 100%;
      background-color: inherit;
      transform: translateX(-50%);
      z-index: -1; 

      @media (min-width: 992px) {
        content: none;
      }
      
    }

    @media (min-width: 992px) {
      padding: 47px 49px;
      margin-top: 0;
    }

  }

  .box-cinza {
    background-color: $cinza;
    padding: 57px 0;
    position: relative;
    z-index: 2;
    margin-top: 77px;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      width: 100vw;
      height: 100%;
      background-color: inherit;
      transform: translateX(-50%);
      z-index: -1; 

      @media (min-width: 992px) {
        content: none;
      }
      
    }

    @media (min-width: 992px) {
      padding: 47px 49px;
      margin-top: 42px;
    }

  }

  .texto {
    color: white;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.16px;
    margin-bottom: 0;
    padding: 40px 0;

    @media (min-width: 992px) {
      font-size: 16px;
      padding: 0;
    }
  }
}


.onde-atuamos_sect {
  position: relative;
  overflow: hidden;
  padding: 48px 0 0;

  @media(min-width: 992px) {  
    padding: 112px 0 169px;
  }

  @media (min-width: 1200px){
    overflow: visible;
  }

  .bg-wrapper,
  .bg{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .bg-wrapper{
    width: 0;
    overflow: hidden;
    height: 100%;
    transition: width ease 1.6s;

    @media (min-width: 1200px){
      height: 110%;
    }

    &.animated{
      width: 100%;
    }
  }

  .bg{
    width: 100vw;
    height: 100%;
    background-image: url('../img/bg-listras-coloridas.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @media (min-width: 1200px){
      background-position: left center;
      background-size: contain;
    }
  }

  &.bg-off {
    background-image: none;
  }

  &.small-padding {

    @media(min-width: 992px) {
      padding-bottom: 82px;
    }    
  }

  .flutuante_image {
    position: absolute;
    left: 0;
    top: 30%;
  }


  .container {
    position: relative;  
    z-index: 2; 
  }

  .onde-atuamos_carousel {
    margin-top: 40px;
    overflow: visible;
    padding-bottom: 118px;

    @media(min-width: 650px){
      overflow: hidden;
    }

    @media(min-width: 992px) {
      margin-top: 32px;
      padding-bottom: 0;
    }     

    .onde-atuamos_card {
      display: flex;      
      position: relative;
      width: 100%;
      height: 412px;    
      cursor: pointer;
      overflow: hidden;

      @media(min-width: 992px) {
        height: 359px;
      }

      &:hover {
        .card_body {
          background-color: $laranja;
        }
      }

      .image_holder {
        position: relative;
        width: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: calc(100% - 82px);

        @media(min-width: 992px) {
          height: calc(100% - 90px);
        }

        &::after {
          content: '';
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 6px;
          background: linear-gradient(90deg, #EA5B0B 0%, rgba(255, 255, 255, 0.00) 100%);
        }
      }

      .card_body {
        position: absolute;
        top: calc(100% - 82px);
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 20px;
        color: white;
        background-color: $preto;
        transition: 0.4s ease;

        @media(min-width: 992px) {
          top: calc(100% - 90px);
        }

        .close_btn {
          position: absolute;
          right: 20px;
          top: 20px;
          background-color: transparent;
          outline: none;
          border: 0;
          padding: 0;
          opacity: 0;
          visibility: hidden;
          transition: 0.4s ease;

          &:hover {
            opacity: 0.8;
          }
        }

        .content {

          .title_holder {
            display: flex;
            align-items: center;
            height: 42px;
            margin-bottom: 20px;

            @media(min-width: 992px) {
              height: 50px;
            }
          }
          .card_title {
            font-size: 20px;        
            font-weight: 500;
            line-height: normal;       

            @media(min-width: 992px) {
              font-size: 24px; 
            }
          }

          .card_desc {
            font-size: 14px;         
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.14px;
            margin-bottom: 0;
          }
        }

        .links_wrapper {
          display: flex;
          align-items: center;
          gap: 10px;
          margin-top: 20px;

          .card_link {
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1;
            padding: 10px;
            height: 70px;
            text-decoration: none;

            &.bgc-amarelo {
              background-color: $amarelo;
            }

            &.bgc-preto {
              background-color: $preto;
            }
          }
        }      
      }

      &.active {
        cursor: default;

        .card_body {
          background-color: $laranja;
          top: 0;

          .close_btn {         
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
  }
}

.sect_transformar-faca {
  width: 100%;
  height: 450px;
  position: relative;
  z-index: 1;

  @media (min-width: 992px) {
    height: 636px;
  }

  .img-arvores {
    position: absolute;
    height: 450px;
    background-image: url('../img/arvores-sect-faca.png');
    z-index: 1;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    right: 0;

    @media (min-width: 992px) {
      width: 78%;
      height: 636px;
    }
  }

  .img-mulher {
    position: absolute;
    height: 450px;
    background-image: url('../img/mulher-sect-faca.png');
    z-index: 1;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    left: 0;

    @media (min-width: 992px) {
      width: 78%;
      height: 690px;
    }
  }

  .grafismo-laranja-verde {
    background-image: url('../img/grafismo-verde-desk.png');
    position: absolute;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: white;
    width: 22%;
    height: 636px;
    display: none;

    @media (min-width: 992px) {
      display: block;
    }
  }

  .grafismo-laranja {
    background-image: url('../img/grafismo-laranja-3.png');
    position: absolute;
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
    top: 0;
    right: 0;
    z-index: 1;
    background-color: white;
    width: 22%;
    height: 690px;
    display: none;

    @media (min-width: 992px) {
      display: block;
    }
  }

  .box {
    background-color: $preto;
    padding: 32px 22px;
    position: relative;
    z-index: 2;
    margin-top: 86px;

    @media (min-width:992px) {
      margin-top: 118px;
      padding: 56px 52px 56px 56px;
    }  
  }

  .box-cinza {
    background-color: $cinza-escuro;
    padding: 32px 22px;
    position: relative;
    z-index: 2;

    @media (min-width: 992px) {
      padding: 56px 66px 56px 60px;
    }

    @media (min-width: 1200px) {
      padding: 56px 72px 56px 60px;
    }
  }

  .bg-gradiente {
    width: 100%;
    height: 6px;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #EA5B0B 100%);
    display: block;
    position: relative;
    z-index: 3;
  }
  
  
  .texto-faca {
    color: #FFF;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    margin: 16px 0;
  
    @media (min-width: 992px) {
      font-size: 16px;
      margin: 32px 0;
    }
  }
  
  .link-faca {
    color: $laranja;
    text-decoration: none;
    display: inline-block;
    margin-bottom: 0;
    transition: all ease-in-out .3s;
    line-height: 9px;
  
    &:hover {
      color: #CD3C00;
    }
  
    &::after {
      content: "";
      background: linear-gradient(90deg, #FF5F19 0%, rgba(255, 255, 255, 0.00) 100%);
      width: 100%;
      height: 2px;
      display: inline-block;
    }
  }
}

.section-card-horizontal{
  // background-color: $preto;
  padding-top: 108px;
  padding-bottom: 125px;
  position: relative;
  z-index: 1; 

  @media (min-width:992px) {
    padding-top: 195px;
    padding-bottom: 170px;
  }

  .flutuante-card{
    width: 180px;
    position: absolute;
    bottom: 45px;
    left: -40px;
    z-index: -1;

    @media (min-width:992px){
      width: initial;
      bottom: -40px;
      left: 0;
    }
  }
}

.card-horizontal{
  background-color: $preto;
  
  &.margin-bottom{
    margin-bottom: 24px;

    @media (min-width:992px){
      margin-bottom: 100px;
    }
  }

  .card-header{
    height: 255px;
    display: flex;
    align-items: flex-end;
    background-size: cover;
    background-position: left center;
    background-repeat: no-repeat;
    background-position: left top;
    padding-left: 20px;
    padding-bottom: 24px;

    @media (min-width:992px) {
      padding-left: 78px;
      padding-bottom: 0;
      align-items: center;
    }

    .card-titulo{
       width: 223px;
       background-color: $preto;
       color: white;
       font-family: 'versos', sans-serif;
       font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        padding: 23px 15px 23px 29px;
        margin-bottom: 0;

        @media (min-width:992px) {
           width: 100%;
           font-size: 28px;
           padding: 29px 26px;
        }
    }
  }

  .card-body{
    background-color: white;
    padding: 24px  24px 30px;

    @media (min-width:992px) {
       padding: 28px 32px 32px 104px;
    }

    .card-paragrafo{
      font-family: 'versos', sans-serif;
      color: $cinza-escuro;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 24px;

      @media (min-width:992px) {
        margin-bottom: 18px;
     }
    }
    
    .card-link{
      font-family: 'margem-rounded', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; 
      color: $laranja;
      text-decoration: none;
      padding-bottom: 4px;
      position: relative;
      transition: all .3s ease;

      &:hover{
        opacity: .6;
      }

        &::after{
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          width: 100%;
          height: 2px;
          background: linear-gradient(90deg, #EA5B0B 0%, rgba(255, 255, 255, 0.00) 100%);
        }
    }
  }

  .card-image{
    background-size: cover;
    background-position: left top;
    background-repeat: no-repeat;
  }
}

.mulher {
  display: flex;
  align-items: flex-end;

  @media (min-width: 992px) {
    align-items: center;
    height: 690px;
  }

  .bg-gradiente {
    margin-bottom: 30px;

    @media (min-width: 992px) {
      margin-bottom: 0;
    }
  }
}

.sect_certificacoes {
  background-color: $preto;  
  padding-bottom: 76px;
  position: relative;
  overflow: hidden;

  @media (min-width: 992px) {    
    padding-bottom: 183px;
  }


  .titulo {
    text-align: center;
    margin-bottom: 34px;

    @media (min-width: 992px) {
      margin-bottom: 52px;
    }
  }

  .img-cert {
    width: 119px; 

    @media (min-width: 768px) {
      width: initial;
    }
  }

  .img-cert-2 {
    width: 60px;
    height: 94px;

    @media (min-width: 768px) {
      width: initial;
      height: initial;
    }
  }

  .img {
    margin-bottom: 16px;

    @media (min-width: 992px) {
      margin-right: 24px;
      margin-bottom: 0;
    }
  }

  .img-2 {
    margin: 0 22px;

    @media (min-width: 992px) {
      margin: 0 69px;
    }
  }

  .flutuante-1-cert {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .flutuante-3-cert {
    position: absolute;
    right: 0;
    bottom: -123px;
  }

  .flutuante-2-cert {
    position: absolute;
    left: -15px;
    top: -133px;
    width: 107px;
    transform: rotate(9deg);

    @media (min-width: 992px) {
      left: 0;
      top: -228px;
      transform: none;
      width: initial;
    }
  }
}

.banner-interna {
  position: relative;
  overflow: hidden;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 543px;
  display: flex;
  align-items: flex-end;

  @media (min-width: 992px) {
    height: 568px;
    align-items: center;
  }

  @media(min-width: 1200px){
    height: calc(100vh - 122.73px);
    max-height: 660px;
    min-height: 400px;
  }

  &.banner-interna--oleo-base {
    position: relative;
    .container {
      justify-content: flex-end;

      @media(min-width: 1200px){
        justify-content: center;
      }
    }   
    
    .arrow-down-indicator {
      display: none;
      align-items: center;
      justify-content: center;

      height: 29px;
      width: 50px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;

      color: white;
      background-color: #19191E; 
      
      padding-top: 5px;

      @media(min-width: 992px){
        display: flex;
      }
    }
  }

  &.banner-interna--lubrin {
    position: relative;
    max-height: 568px;
    min-height: 543px;
    background-image: url('../img/bg-banner-lubrin-mobile.jpg');
    background-position: top center;
    background-size: cover;

    @media(min-width: 768px){
      background-image: url('../img/bg-banner-lubrin.jpg');
    }

    .container {
      justify-content: flex-end;

      @media(min-width: 1200px){
        justify-content: center;
      }
    }   

    .box-laranja{
    
      @media(min-width: 992px){
        max-width: 347px;
      }
    }
  }
  

  .bg-video {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }


  .titulo-banner-interna {
    color: #FFF;
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 0;

    @media (min-width: 992px) {
      font-size: 36px;
    }
  }

  .texto-banner-interna {
    color: #FFF;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0;

    @media (min-width: 992px) {
      font-size: 16px;
    }
  }

  .box-laranja {
    background-color: $laranja;
    padding: 32px 37px 33px 38px;

    @media (min-width: 992px) {
      padding: 32px 21px 31px 32px;
    }
  }

  .box-preto {
    background-color: $preto;
    padding: 19px 21px 15px 20px;
    margin-bottom: 27px;

    @media (min-width: 992px) {
      padding: 26px 26px 26px 27px;
      margin-bottom: 0;
    }
  }

  .box-roxo {
    background-color: $roxo;
    padding: 38px 31px 39px 38px;

    @media (min-width: 992px) {
      padding: 49px 21px 48px 32px;
    }
  }

  .container{
    position: relative;
    z-index: 2;

    @media (max-width: 992px) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
     }

    // .img-selo{
    //   max-width: 103px;
    //   align-self: flex-end;

    //   @media (min-width: 992px) {
    //     max-width: 180px;
    //     position: absolute;
    //     top: calc((100% / 2) - 90px);
    //     left: 0;
    //     align-self: normal;
    //   }
    // }

  }
  

  &::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    display: block;
    width: 100%;
    height: 100%;
    background-color: $laranja;
    mix-blend-mode: multiply;
    opacity: 0.2;
  }

  .iconic-banner{
    max-width: 203px;

    @media (min-width: 992px) {
      max-width: 309px;
    }
  }
}

.sect_banco {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  background-color: $cinza-escuro;
  
  .area-texto {
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;

    @media (min-width: 992px) {
      height: 500px;
      padding: 0;
    }
  }

  .bg-banco {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 290px;
    width: 100%;

    @media (min-width: 992px) {
      height: 500px;
      width: 50%;
      top: 0;
      right: 0;
      position: absolute;
    }
  }

  .titulo {
    margin-bottom: 16px;

    @media (min-width: 992px) {
      margin-bottom: 24px;
    }
  }

  .img-selo{
    height: 73px;

    @media (min-width: 992px) {
      height: 85px;
    }
  }

  .texto-banco {
    margin-bottom: 16px;
    color: #FFF;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.14px;

    @media (min-width: 992px) {
      margin-bottom: 24px;
      font-size: 16px;
      max-width: 370px;
    }
  }

  .link-banco {
    color: $laranja;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    margin-top: 24px;
    margin-bottom: 0;
    text-decoration: none;
    display: inline-block;
    line-height: 9px;

    @media (min-width: 992px) {
      margin-top: 36px;
    }

    &:hover {
      color: #CD3C00;
    }

    &::after{
      content: "";
      display: inline-block;
      width: 100%;
      height: 2px;
      background: linear-gradient(90deg, #EA5B0B 0%, rgba(255, 255, 255, 0.00) 100%);
    }
  }

  .fw-700 {
    font-weight: 700;
  }

}

.sect-destaque{
  padding-top: 40px;
  margin-bottom: 40px;

  @media (min-width: 992px){
    padding-top: 56px;
    margin-bottom: 56px;
 }

  .titulo{
    font-size: 24px;
    margin-bottom: 24px;

    @media (min-width: 992px){
      font-size: 36px;
   }
  }
}

.sect-publicacoes{
  margin-bottom: 36px;

  @media (min-width: 992px){
    margin-bottom: 80px;
 }

  .titulo{
    font-size: 24px;
    margin-bottom: 24px;

    @media (min-width: 992px){
      font-size: 36px;
   }
  }

  .row{
    margin-bottom: 36px;

    @media (min-width: 992px){
      margin-bottom: 65px;
   }
  }

  .btn{
    width: 100%;
    color: white;

    @media (min-width: 992px){
      max-width: 384px;
    }
  }
}

.card-imprensa{
  text-decoration: none;

  &:hover {

    .card-image {
      scale: 1.1;
    }

    .card-body {
      .card-titulo {
        color: $laranja;
      }
    }
 
  }

  .img-holder {
    overflow: hidden;
    margin-bottom: 16px;

    @media (min-width: 992px) {
      margin-bottom: 24px;
    }
  }

  .card-image{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    aspect-ratio: 16 / 9;
    transition: all ease-in-out .3s;

  }
  .card-body{

    .card-titulo{
       color: $preto;
       font-family: 'versos', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 16px;
        transition: all ease-in-out .3s;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;   
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        @media (min-width: 992px){
          font-size: 24px;
        }
    }
    .card-texto{
      color: $cores-neutras-cinza-7;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; 
      letter-spacing: -0.14px;
      margin-bottom: 16px;

      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;   
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

      @media (min-width: 992px){
        font-size: 16px;
        font-weight: 400;
      }
    }
  }


  &.card-imprensa-horizontal{
    padding-top: 20px;
    padding-bottom: 0;

    @media (min-width: 992px){
      display: flex;
      gap: 24px;
      padding-top: 36px;
      padding-bottom: 36px;

      .img-holder {
        width: 283px;
        min-width: 283px;
        height: 179px;
        margin-bottom: 0;
      }

      .card-image{
        width: 283px;
        min-width: 283px;
        height: 179px;
        margin-bottom: 0;
      }
    }

    .card-body{

      .card-titulo{
        margin-bottom: 8px;

        @media (min-width: 992px){
          margin-top: 15px;
          margin-bottom: 16px;
        }
      }
   }
  }
}

.border-bottom {
    border-bottom: 1px solid #DCDCDC;
}

.img-imprensa {

  .area-texto {
    padding: 65px 0 82px 0;
    align-items: center;

    @media (min-width: 992px) {
      height: 320px;
      align-items: flex-start;
    }
  }

  .titulo {
    margin-bottom: 16px;
    font-size: 32px;

    @media (min-width: 992px) {
      font-size: 36px;
    }
  }

  .texto-banco {
    margin-bottom: 16px;
  }

  .link-banco {
    margin-top: 0;
  }

  .bg-banco {
    display: none;

    @media (min-width: 992px) {
      display: block;
      height: 320px;
    }
  }
}

.breadcrumb{
  background-color: $laranja;
  padding: 10px;
  margin-bottom: 0;

  &-list{
    display: flex;
    align-items: center;
    list-style-type: none;
    padding: 0;
    margin-bottom: 0;
    gap: 4px;


    &_item{
        color: white;
        font-family: 'versos', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; 

        &.active{
          color: #FF8C5A;
        }

      .breadcrumb-link{
        text-decoration: none;
      }
    }
  }
}

.banner-imprensa {
  background-position: -297px;

  @media (min-width: 992px) {
    background-position: center;
  }

  &::before{
    background-color: $roxo;
  }
}

.btn-voltar{
  color: $cinza-escuro;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.14px;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  gap: 8px;

  margin-top: 40px;
  margin-bottom: 40px;

  &:hover{
    opacity: .6;
  }
}

.noticias{
  margin-bottom: 72px;

  @media (min-width: 992px){
    margin-bottom: 100px;
  }

  .titulo{
    color: #3A414B;
    font-family: 'versos', sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    margin-bottom: 16px;

    @media (min-width: 992px) {
       line-height: normal;
       color: $cinza-escuro;
       margin-bottom: 30px;
    }
  }
  .subtitulo{
    color: $cores-neutras-cinza-6;
    font-style: normal;
    font-weight: 500;
    letter-spacing: -0.16px;
    font-size: 20px;
    line-height: 150%; 
    margin-bottom: 32px;

    @media (min-width: 992px){
      font-size: 16px;
      line-height: normal;
      margin-bottom: 25px;
    }
  }
  .texto-data{
    color:  $cores-neutras-cinza-7;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;

    @media (min-width: 992px){
      line-height: normal;
      letter-spacing: -0.14px;
    }
  }
  .line{
    height: 1px;
    background-color: $cinza;
    margin-top: 32px;
    margin-bottom: 24px;

    @media (min-width: 992px){margin-top: 24px;}
  }
  .img-noticia-interna{
    width: 100%;
    margin-bottom: 24px;

    @media (min-width: 992px){
      margin-bottom: 40px;
    }
  }
  .paragrafo{
    font-family: 'versos', sans-serif;
    color: $cinza-escuro;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    & + .titulo-small {
      margin-top: 40px;
    }
  }

  .titulo-small {
    color: #19191E;
    font-family: 'versos', sans-serif;
    font-size: 24px;  
    font-weight: 700;
    line-height: normal;
    margin: 0 0 40px;    
  }

  .btn{
    margin-top: 58px;
    width: 100%;
    color: white;

    @media (min-width: 992px){
      margin-top: 40px;
      width: 180px;
    }
  }
}

.fale-conosco_sect {
  padding-top: 40px;

  @media(min-width: 992px) {
    padding-top: 56px;
  }

  .container {
    position: relative;

    .flutuante_img {
      display: none;
      position: absolute;
      left: 7%;
      bottom: 20%;
      z-index: -1;

      @media(min-width: 992px) {
        display: block;
      }
    }
  }

  .subtitle {
    font-size: 16px;   
    line-height: normal;
    margin: 16px 0;

    @media(min-width: 992px) {
      font-size: 24px;
      margin: 28px 0;
    }
  }

  .text {
    color: $cores-neutras-cinza-7;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.14px;
    margin-bottom: 0;

    @media(min-width: 992px) {
      font-size: 16px;
      letter-spacing: -0.16px;
    }

    + .text {
      margin-top: 16px;
    }
  }

  .contato_box {
    position: relative;
    z-index: 2;
    width: 100%;
    padding: 24px;
    background-color: $cinza-f8;
    border: 1px solid $cinza;
    margin-top: 32px;

    @media(min-width: 992px) {
      margin-top: 0;
    }

    .form_title {
      font-size: 24px;    
      line-height: normal;
      margin-bottom: 15px;

      @media(min-width: 992px) {
        margin-bottom: 24px;
      }
    }
  }

  .form-textarea {
    min-height: 124px;
  }
}

.enderecos_sect {
  padding: 40px 0 32px;

  @media(min-width: 992px) {
    padding: 32px 0 98px;
  }

  .titulo {
    margin-bottom: 36px;

    @media(min-width: 992px){
      margin-bottom: 30px;
    }
  }

  .mapa {
    width: 100%;
    aspect-ratio: 16/8;
    margin-bottom: 16px;

    @media(min-width: 992px){
      margin-bottom: 0;
    }
  }

  .endereco_box {   

    .title {
      font-size: 20px;     
      font-weight: 700;
      line-height: normal;
      margin-bottom: 16px;

      @media(min-width: 992px) {
        font-size: 24px;   
      }
    }

    .localizacao_text {
      display: flex;
      align-items: center;
      gap: 8px;
      color: $laranja;  
      font-size: 16px;       
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.16px; 

      @media(min-width: 992px) {
        font-size: 20px;  
        letter-spacing: -0.2px;
      }    
    }

    .info {
      color: $cores-neutras-cinza-7;    
      font-size: 14px;      
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.14px;
      margin-bottom: 0;

      @media(min-width: 992px) {
        font-size: 16px;  
        letter-spacing: -0.16px;
      }  
    }
  }

  .fabricas_content {
    margin-top: 24px;

    @media(min-width: 992px) {
      margin-top: 47px;
    }

    .titulo {
      margin-bottom: 24px;
    }
  }
}

.infra_sect {
  background-image: url('../img/bg-listras-cinzas.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 69px 0 65px;

  @media(min-width: 992px){
    padding: 62px 0;
  }

  .box-laranja {
    padding: 32px 24px;
    background-color: $laranja;

    @media(min-width: 992px) {
      padding: 50px 72px;
    }
  }

  .box-preto {
    padding: 32px 24px 42px;
    background-color: $preto;

    @media(min-width: 992px) {
      padding: 30px 40px;
    }

    .text {
      color: white;
      margin-bottom: 0;
      font-size: 18px; 
      line-height: normal;
    }
  }
}

.conheca-numeros_sect {
  position: relative;
  padding: 40px 0 0;
  overflow: hidden;

  @media(min-width: 992px) {
    padding: 72px 0 150px;
    overflow: visible;
  }

  .texto {
    color: #797E86;
    font-size: 16px; 
    font-weight: 500;
    line-height: 20px; 
    letter-spacing: -0.16px;
    margin: 8px 0 0;

    @media(min-width: 992px){
      margin-top: 16px;
    }
  }

  &.small-padding {

    @media(min-width: 992px) {
      padding-bottom: 100px;
    }    
  }

  .flutuante_img {
    display: none;
    position: absolute;
    left: 0;
    bottom: 0;

    @media(min-width: 992px) {
      display: block;
    }
  }

  .container {
    position: relative;
  }

  .conheca-numeros_carousel {
    margin-top: 32px;
    padding-bottom: 104px;
    overflow: visible;

    @media(min-width: 992px) {
      margin-top: 40px;
      padding-bottom: 0;
      overflow: hidden;
    }

    .swiper-slide {
      height: auto;
    }

    .numeros_card {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      background-color: $preto;
      padding: 32px 24px 44px;
      font-family: 'versos', sans-serif;

      @media(min-width: 992px) {
        padding: 32px 50px 44px;
      }   
      
      &::after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 12px;
        background: linear-gradient(90deg, #FC4E00 0%, rgba(255, 255, 255, 0.00) 100%);        
      }
      
      .intro {       
        color: $laranja;
        margin-bottom: 16px;

        .number {          
          font-size: 48px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          margin-bottom: 16px;

          @media(min-width: 992px) {
            font-size: 56px;
          }

          .small {
            font-size: 32px;

            @media(min-width: 992px) {
              font-size: 35px;
            }
          }
        }

        .title {
          font-size: 36px;      
          line-height: normal;
          margin-bottom: 0;
        }
      }

      .desc {
        margin-bottom: 0;
        font-size: 20px;    
        line-height: normal;
        color: white;
      }
    }

    &.somos-iconic{

      .numeros_card{
        @media(min-width: 992px) {
          padding: 54px 50px 76px;
        }
      }

      .intro{
        @media(min-width: 992px) {
          margin-bottom: 48px;
        }
      }
    }
  }

  .cards-padrao-holder {
    margin-top: 32px;
    padding-bottom: 104px;
    overflow: visible;
  
    @media(min-width: 992px) {
      margin-top: 40px;
      padding-bottom: 0;
      overflow: hidden;
    }
  
    .swiper-slide {
      height: auto;
    }
  
    .numeros_card {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      background-color: $preto;
      padding: 32px 24px 44px;
      font-family: 'versos', sans-serif;
  
      @media(min-width: 992px) {
        padding: 32px 50px 44px;
      }   
      
      &::after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 12px;
        background: linear-gradient(90deg, #FC4E00 0%, rgba(255, 255, 255, 0.00) 100%);        
      }
      
      .intro {       
        color: $laranja;
        margin-bottom: 16px;
  
        .number {          
          font-size: 48px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          margin-bottom: 16px;
  
          @media(min-width: 992px) {
            font-size: 56px;
          }
  
          .small {
            font-size: 32px;
  
            @media(min-width: 992px) {
              font-size: 35px;
            }
          }
        }
  
        .title {
          font-size: 36px;      
          line-height: normal;
          margin-bottom: 0;
        }
      }
  
      .desc {
        margin-bottom: 0;
        font-size: 20px;    
        line-height: normal;
        color: white;
      }
    }
  
    &.somos-iconic{
  
      .numeros_card{
        @media(min-width: 992px) {
          padding: 54px 50px 76px;
        }
      }
  
      .intro{
        @media(min-width: 992px) {
          margin-bottom: 48px;
        }
      }
    }
  }

  
}

.strength_sect {
  padding-bottom: 42px;

  @media(min-width: 992px) {
    padding-bottom: 56px;
  }

  .strength_card {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .image_holder {
      width: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;

      &::after {
        content: '';
        display: block;
        padding-bottom: 72%;
      }
    }

    .content_holder {
      background-color: $preto;
      flex: 1;

      @media(min-width: 992px) {
        position: relative;
        padding: 85px 58px 0;
      }

      .box {
        display: flex;
        align-items: center;
        padding: 16px 18px;  
        min-height: 90px;
        
        @media(min-width: 992px) {
          max-width: 340px;
          position: absolute;
          left: 58px;
          top: -76px;
          padding: 25px 16px 25px 38px; 
        }

        &.laranja {
          background-color: $laranja;
          color: white;
        }
      }

      .content {
        padding: 30px 18px;

        @media(min-width: 992px) {
          padding: 0 0 50px;
        }

        .desc {
          font-family: 'versos', sans-serif;
          margin-bottom: 0;
          color: white;
          font-size: 14px;
          line-height: normal;

          @media(min-width: 992px) {
            font-size: 16px;
          }
        }
      }
    }
  }
}

.animated-item, .custom-animated-item {
  transition: 0.8s ease;
  opacity: 0;

  &.animated {
    opacity: 1;
  }
}

.animate-top-in { 
  transform: translateY(100px);  

  &.animated {
    transform: translate(0, 0);
  }
}

.animate-left-in {
  transform: translateY(100px);

  @media(min-width: 992px){
    transform: translate(-100px, 0);  
  }

  &.animated {
    transform: translate(0, 0);
  }
}

.animate-right-in {
  transform: translateY(100px);

  @media(min-width: 992px){
    transform: translate(100px, 0);   
  }

  &.animated {
    transform: translate(0, 0);
  }
}

.banner-sustentabilidade {
  position: relative;
  align-items: flex-start;
  
  .logo-banner {
    display: flex;
    justify-content: center;
    padding-top: 76px;

    @media (min-width: 992px) {
      padding-top: 117px;
    }

    .logo {
      width: 245px;
      height: 227px;

      @media (min-width: 992px) {
        width: initial;
        height: initial;
      }
    }
  }

  .flutuante-1-sustenta {
    display: none;

    @media (min-width: 992px) {
      display: block;
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }

  .flutuante-2-sustenta {
    position: absolute;
    right: 0;
    bottom: 0;

    @media (min-width: 992px) {
      display: none;
    }
  }
}

.sect-sustentabilidade {
  background-color: $laranja;
  position: relative;

  @media (min-width: 992px) {
    height: 500px;
  }

  .area-texto {
    margin: 40px 0;

    @media (min-width: 992px) {
      padding: 0;
      margin: 138px 0 0 0;
    }
  }

  .titulo {
    font-size: 32px;
    margin-bottom: 24px;

    @media (min-width: 992px) {
      font-size: 36px;
      margin-bottom: 32px;
      max-width: 377px;
    }
  }

  .video-holder-susten {
    width: 100%;
    height: 390px;
    z-index: 1;

    @media (min-width: 992px) {
      height: 500px;
      position: absolute;
      right: 0;
      top: 0;
      width: 50%;
    }
  }

  .play-video {
    position: absolute;
    right: 50%;
    top: 50%;
    transform: translate(50%, -50%);
    z-index: 2;
    cursor: pointer;
  }

  .bg-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }
}

.sect_transformar-susten {
  width: 100%;
  position: relative;
  z-index: 1;
  background-color: #E5E6E7;

  @media (min-width: 992px) {
    height: 690px;
  }

  .grafismo-laranja-verde {
    background-image: url('../img/grafismo-verde-2-mobile.png');
    position: absolute;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: white;
    width: 100%;
    height: 57px;

    @media (min-width: 992px) {
      height: 690px;
      width: 41%;
      background-image: url('../img/grafismo-verde-2.png');
      background-position: left;
    }
  }

  .area-box {
    position: relative;
    z-index: 2;
    padding-top: 57px;

    @media (min-width:992px) {
      padding-top: 209px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }

  .box {
    background-color: $laranja;
    padding: 40px 26px 40px 0;
    position: relative;
    z-index: 2;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      width: 100vw;
      height: 100%;
      background-color: inherit;
      transform: translateX(-50%);
      z-index: -1; 

      @media (min-width: 992px) {
        content: none;
      }
    }
    
    @media (min-width:992px) {
      padding: 32px 63px;
      width: min-content;
      margin-right: 75px;
    }  

    .titulo {
      font-size: 32px;

      @media (min-width: 992px) {
        font-size: 36px;
      }
    }
  }

  .box-preto {
    background-color: $preto;
    padding: 23px 0;
    position: relative;
    z-index: 2;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      width: 100vw;
      height: 100%;
      background-color: inherit;
      transform: translateX(-50%);
      z-index: -1; 

      @media (min-width: 992px) {
        content: none;
      }
    }

    @media (min-width: 992px) {
      padding: 27px 30px 28px 23px;
      max-width: fit-content;
      margin-right: 13px;
    }
  }

  .texto-area {
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 112px;
    margin-bottom: 112px;

    @media (min-width: 992px) {
      margin-top: 187px;
      margin-bottom: 0;
      padding: 0 65px 0 37px;
    }
  }

  .texto-sustentabilidade {
    color: $cinza-escuro;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 0;
  }

  .flutuante-texto-1 {
    position: absolute;
    top: -48px;
    left: 12px;

    @media (min-width: 992px) {
      top: -45px;
      left: 0px;
    }
  }

  .flutuante-texto-2 {
    position: absolute;
    bottom: -48px;
    right: 11px;

    @media (min-width: 992px) {
      bottom: -28px;
      right: 0px;
    }
  }
}

.sect-pilares {
  position: relative;
  padding: 56px 0;
  overflow-x: clip;

  @media (min-width: 992px) {
    padding: 80px 0 112px;
  }

  .titulo {
    font-size: 32px;
    margin-bottom: 32px;

    @media (min-width: 992px) {
      font-size: 48px;
      margin-bottom: 40px;
    }
  }

  .flutuante-pilares {
    position: absolute;
    right: 0;
    bottom: -40%;
    display: none;
    z-index: 1;

    @media (min-width: 992px) {
      display: block;
      bottom: -27%;
    }

    @media (min-width: 1200px) {
      bottom: -40%;
    }
  }
  .swiper {
    position: relative;
    z-index: 2;
    overflow: visible;
  }

  .swiper-wrapper {
    @media (min-width: 992px) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 24px;
    }
  }

  .swiper-slide {
    background-color: #E5E6E7;
    min-height: 230px;
    width: 283px;
    padding: 32px 23px 29px 32px;
    border-bottom: 6px solid;
    border-image: linear-gradient(90deg, #EA5B0B 0%, rgba(255, 255, 255, 0.00) 100%);
    border-image-slice: 1;
    height: 6px;

    @media (min-width: 992px) {
      width: 260px;
      min-height: 250px;
    }

    @media (min-width: 1400px) {
      width: 283px;
      min-height: 230px;
    }

    .img-box {
      margin-bottom: 32px;
    }
    
    .titulo-box {
      color: $preto;
      font-size: 24px;
      font-weight: 700;
      line-height: 24px;
      margin-bottom: 0;
    }
  }
}

.sect_construir {
  background-color: $laranja;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  
  @media (min-width: 992px) {
    height: 500px;
    flex-direction: row;
  }

  .construir-img {
    height: 291px;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 5;

    @media (min-width: 992px) {
      position: absolute;
      top: 0;
      right: 0;
      height: 500px;
      width: 50%;
    }
  }

  .texto-construir {
    color: #FFF;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.16px;
    margin-bottom: 40px;
    margin-top: 24px;

    @media (min-width: 992px) {
      margin-top: 16px;
      margin-right: 37px;
      margin-bottom: 0;
    }
  }
}

.acoes-iconic_sect {
  overflow: hidden;
  padding: 48px 0 0;

  @media(min-width: 992px) {  
    padding: 112px 0 169px;
  }

  .container {
    position: relative;    
  }

  .acoes-iconic_carousel {
    margin-top: 40px;
    overflow: visible;
    padding-bottom: 118px;

    @media(min-width: 650px){
      overflow: hidden;
    }

    @media(min-width: 992px) {
      margin-top: 32px;
      padding-bottom: 0;
    }     

    .acoes_card {
      display: flex;      
      flex-direction: column;
      width: 100%;
      height: 434px;    
      overflow: hidden;
      text-decoration: none;
      border: none;
      outline: none;
      background-color: transparent;
      text-align: left;

      &:hover {
        .titulo-card {
          color: $laranja;
        }

        .image_holder {
          scale: 1.2;
        } 

      }

      .img-container {
        width: 100%;
        height: 251px;
        overflow: hidden;
        margin-bottom: 16px;
      }

      .image_holder {
        width: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 251px;
        transition: all ease-in-out .3s;
      }

      .titulo-card {
        color: $preto;
        font-size: 24px;
        font-weight: 400;
        margin-bottom: 16px;
        transition: all ease-in-out .3s;
      }

      .texto-card {
        color: $cores-neutras-cinza-7;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.14px;
        min-height: 62px;
        margin-bottom: 16px;

        // limitar texto 3 linhas
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        white-space: normal;

        @media (min-width: 992px) {
          font-size: 16px;
        }
      }

      .link-card {
        color: $laranja;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        display: inline-block;
        line-height: 9px;
        text-decoration: none;

        &::after{
          content: "";
          display: inline-block;
          width: 100%;
          height: 2px;
          background: linear-gradient(90deg, #EA5B0B 0%, rgba(255, 255, 255, 0.00) 100%);
        }
      }

    }
  }
}

.entenda-atuacao_sect {
  position: relative;

  @media(min-width: 992px){
    display: flex;
    align-items: center;
    padding: 50px 0;
    min-height: 539px;
  }

  .grafismo {
    display: none;
    background-image: url('../img/img-grafismo-laranja-md.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @media(min-width: 992px) {
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 29%;
    }
  }

  .image_holder {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 38px 0;
    background-color: $cinza-61;

    @media(min-width: 992px) {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 71%;
      padding: 40px 90px;      
    }
  }

  .container {
    position: relative;
    z-index: 2;

    @media(max-width: 991px) {
      max-width: initial;
      padding: 0;
    }

    .laranja, .preto {
      width: 100%;

      @media(min-width: 992px) {
        max-width: 340px;
      }
    }

    .preto {      

      @media(min-width: 992px) {
        margin-left: 48px;
      }
    }
  }
}

.trabalhamos-etica_sect {
  overflow: hidden;
  padding-bottom: 48px;

  @media(min-width: 992px) {
    padding-bottom: 112px;
  }

  .intro {
    color: $cores-neutras-cinza-7;
    font-size: 16px;  
    font-weight: 500;
    line-height: 20px; 
    letter-spacing: -0.16px;
    margin-bottom: 32px;
    margin-top: 16px;

    @media(min-width: 992px) {
      margin-bottom: 36px;
    }
  }

  .trabalhamos-etica_carousel {
    overflow: visible;

    @media(min-width: 992px) {
      overflow: hidden;
    }

    .swiper-slide {
      height: auto;
    }
  }

  .trabalhamos-etica_card {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .card_header {
      flex: 1;
      padding: 30px 20px 45px;
      background-color: $laranja;      

      .card_title {
        color: white;
        font-family: 'versos', sans-serif;
        font-size: 20px;       
        font-weight: 700;
        line-height: normal;
        margin-bottom: 0;
      }
    }

    .card_footer {
      position: relative;
      background-color: $preto;
      padding: 27px 20px 25px;

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 6px;
        background: linear-gradient(90deg, $laranja 0%, rgba(255, 255, 255, 0.00) 100%);
      }

      .card_link {
        display: flex;
        align-items: center;
        gap: 14px;
        width: fit-content;
        font-size: 18px;
        font-weight: 500;
        line-height: 20px; 
        letter-spacing: -0.18px;
        color: white;
        text-decoration: none;
        transition: 0.3s ease;

        &:hover {
          color: $laranja;
        }
      }
    }
  }
}

.valorizamos-diversidade_sect {
  overflow: hidden;
  position: relative;
  padding: 146px 0 246px;
  background-color: $preto;

  @media(min-width: 992px) {
    padding: 179px 0 229px;
  }

  .flutuante_image {
    position: absolute;
    left: 0;
    bottom: 25px;

    @media(min-width: 992px) {
      left: initial;
      right: 0;
      bottom: -50px;
    }
  }

  .content {
    width: 100%;
    position: relative;

    @media(min-width: 992px){
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 500px;
    }

    .box-padrao {
      position: relative;
      z-index: 2;   

      &.preto {
        max-width: 223px;

        @media(min-width: 992px) {
          margin-left: 78px;
          max-width: 306px;
          padding-top: 40px;
          padding-bottom: 40px;
        }

        @media(max-width: 991px) {
          position: absolute;
          bottom: 24px;
          left: 21px;
          padding-top: 23px;
          padding-bottom: 23px;
        }
      }

      &.cinza {
        width: 100%;

        @media(min-width: 992px) {
          margin-left: 132px;
          max-width: 353px;
        }
      }
    }

    .bg_holder {
      position: relative;
      width: 100%;

      &::after {
        content: '';
        display: block;
        padding-bottom: 75%;

        @media(min-width: 992px) {
          display: none;
        }
      }

      @media(min-width: 992px) {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;        
      }

      .grafismo {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 32%;
        background-image: url('../img/img-grafismo-laranja-md.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }

      .image {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 68%;
        background-image: url('../img/img-valorizamos-diversidade.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
  }
}

.sect_politica-direitos {
  padding: 122px 0 169px;

  .titulo-sect {
    font-size: 32px;
    margin-bottom: 40px;

    @media (min-width: 992px) {
      font-size: 36px;
      text-align: center;
    }
  }

  .card-download-wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media(min-width: 992px){
      flex-direction: row;
      justify-content: center;
    }

    .card-download {
      min-height: 244px;

      @media(min-width: 992px){
        width: 282px;
      }
    }
  }

  .card-download{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    background-color: $laranja;
    color: white;

    .titulo{
        display: block;
        margin-bottom: 0;
        width: 100%;
        padding: 30px 28px;
        font-family: 'versos';
        font-size: 20px;
        font-weight: 700;
    }

    .download{
        position: relative;
        display: flex;
        align-items: center;
        column-gap: 14px;
        padding: 25px 28px;
        background-color: $preto;
        font-size: 18px;
        line-height: 20px;
        letter-spacing: -0.18px;
        text-decoration: none;
        transition: all ease 0.4s;

        &::after{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 6px;
            background: linear-gradient(90deg, #FC4E00 0%, rgba(255, 255, 255, 0.00) 100%);
        }

        &:hover{
          color: $laranja;
        }
    }
}
}

.link-padrao-cinza {
  position: relative;
  text-decoration: none;
  color: #19191E;
  font-family: 'versos', sans-serif;
  font-size: 16px; 
  line-height: normal;
  transition: 0.3s ease;

  &:hover {
    color: #DCDCDC;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 5px;
    left: 0;
    bottom: -9px;
    background: linear-gradient(90deg, #DCDCDC 0%, rgba(220, 220, 220, 0.00) 100%);
  }
}



.sobre-parceria-sect{
  position: relative;
  overflow: hidden;
  padding-bottom: 80px;
  
  @media (min-width: 992px) {
    padding: 80px 0;
  }
  .flutuante{
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    pointer-events: none;

    @media (min-width: 992px) {
      right: -150px;
    }

    @media (min-width: 1280px) {
      right: 0;
    }
  }

  .img-mobile-box{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 40px;

    .img-logo-lubrin-mobile{
         position: absolute;
         top: calc((100% / 2) - 41px);
         left: 50%;
         transform: translateX(-50%);
    }
  }

  .img-mobile{
    display: block;
    width: 100%;
    max-width: none;
    height: 350px;
    object-fit: cover;
  }

  .box-info{

    .titulo{
      color: #19191E;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 8px;

      @media (min-width: 992px) {
        font-size: 36px;
        margin-bottom: 16px;
      }
    }
    .paragrafo{
      color: #797E86;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.16px;

      @media (min-width: 992px) {
        font-size: 18px;
      }
    }
  }
}

.link-laranja {
  display: inline-block;
  color: $laranja;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 9px;
  margin-bottom: 0;
  text-decoration: none;

  &:hover {
    color: #CD3C00;
  }

  &::after{
    content: "";
    display: inline-block;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, #EA5B0B 0%, rgba(255, 255, 255, 0.00) 100%);
  }
}

.nos-somos-iconic-section {
  position: relative;
  padding: 80px 0 109px;

  @media(min-width: 1200px){
    padding: 159px 0;
  }

  .flutuante {
    display: none;
    position: absolute;
    left: 0;
    top: 0;

    @media(min-width: 1200px){
      display: block;
    }
  }

  .image-holder {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;

    .we-re-iconic-img {
      display: block;        
      max-width: 69px;
  
      @media(min-width: 1200px){
        max-width: 128px;
      }
    }

    .we-re-iconic-title {
      color: #39414C;
      font-family: 'versos', sans-serif;
      font-size: 36px;   
      font-weight: 800;
      line-height: 95%; 
      letter-spacing: 1.08px;
      margin-bottom: 0;

      @media(min-width: 1200px){
        font-size: 66px;            
        letter-spacing: 1.98px;
      }
    }
  } 

  .text-area {
    display: flex;
    justify-content: center;

    .text {
      color: var(--Cores-Neutras-cinza-9, #494C50);
      text-align: center;
      font-family: 'margem-rounded';
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%;
      letter-spacing: -0.18px;
      margin: 24px 0 48px;

      @media (min-width: 992px) {
        font-size: 24px;
        letter-spacing: -0.24px;
        margin: 32px 0 64px;
        max-width: 652px;
      }
    }
  }

  .cards-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;

    @media (min-width: 992px) {
      flex-direction: row;
      flex-wrap: wrap;
    }

    @media(min-width: 1200px){
      flex-direction: row;     
      flex-wrap: nowrap;
      align-items: flex-start; 
    }

    .we-re-iconic-card {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 24px;
      text-align: center;
      border: 1px solid #F2F2F3;
      background: #FFF;
      box-shadow: 0px 0px 16px 4px rgba(0, 0, 0, 0.04);
      padding: 40px 24px;
      min-height: 312px;
      width: 342px;
      position: relative;

      &::after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 8px;        
      }

      @media(min-width: 1200px){
        justify-content: flex-start;
        flex: 1;
        min-height: 362px;
        width: 261px;
      }

      @media (min-width: 1400px) {
        width: 306px;
        min-height: 350px;
      }

      &_intro {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 16px;

        .img {
          max-width: 100px;
        }

        &_title {
          color: #19191E;
          font-family: 'versos', sans-serif;
          font-size: 24px;        
          font-weight: 700;
          line-height: 100%; 
          letter-spacing: -0.24px;
          margin-bottom: 0;
          text-align: left;
          text-wrap: nowrap;

          @media(min-width: 1200px){
            font-size: 28px;   
            letter-spacing: -0.32px;
          }

          @media(min-width: 1400px){
            font-size: 32px;   
            letter-spacing: -0.32px;
          }
        }
      }

      &_text {
        color: #19191E;    
        font-size: 18px;   
        font-weight: 500;
        line-height: 120%; 
        letter-spacing: -0.18px;
        margin-bottom: 0;

        .c-rosa {
          color: #FB006E;
        }

        .c-azul {
          color: #0048CF;
        }

        .c-laranja {
          color: #FF5000;
        }

        .c-amarelo {
          color: #FFB700;
        }
      }
    }

    .bcg-rosa {
      &::after {
        background: linear-gradient(90deg, #FB006E 0%, rgba(255, 255, 255, 0.00) 75%);
      }
    }

    .bcg-azul {
      &::after {
        background: linear-gradient(90deg, #0048CF 0%, rgba(255, 255, 255, 0.00) 75%);
      }
    }

    .bcg-laranja {
      &::after {
        background: linear-gradient(90deg, #FF5000 0%, rgba(255, 255, 255, 0.00) 75%);
      }
    }

    .bcg-amarelo {
      &::after {
        background: linear-gradient(90deg, #FFB700 0%, rgba(255, 255, 255, 0.00) 75%);
      }
    }
  }
}

