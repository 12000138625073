.sect_mais-beneficios--oleo-base {
    .sect_transformar {
        .box-laranja {
            @media(min-width: 992px){
                padding: 56px 40px;
            }
        }
    }

    .box-cinza {
        background-color: $cinza-escuro;
        padding: 32px 0;
        position: relative;
        z-index: 2;
    
        @media (min-width: 992px) {
          padding: 56px 40px;
        }  
        
        .text {
            color: #FFF;         
            font-size: 18px;      
            font-weight: 500;
            line-height: 20px; 
            letter-spacing: -0.18px;
            margin-bottom: 0;

            @media(min-width: 992px){           
                font-size: 24px;           
                font-weight: 400;
                line-height: 140%;
            }
        }
       
      }
    .texto-transformar {
        font-size: 14px;
        padding-bottom: 26px;

        @media(min-width: 992px){
            font-size: 20px;
            padding-bottom: 32px;
        }
    }

    .lista-transformar {
        color: white;
        padding-left: 16px;
        margin-bottom: 0;
        padding-bottom: 56px;
        list-style-image: url('../img/icone-list-style-circle-orange.svg');

        @media(min-width: 992px){       
            padding-left: 69px;
            padding-bottom: 0;
        }

        .list_item {          
            font-size: 20px;      
            font-weight: 500;
            line-height: 120%; 
            letter-spacing: -0.2px;            
        }
    }
}

.infra-sect {
    overflow: hidden;
    position: relative;
    z-index: 2;
    padding: 56px 0 0;

    margin-bottom: -32px;

    @media(min-width: 992px){
        padding: 80px 0 18px;
        margin-bottom: 0;
    }

    .flutuante_img {
        position: absolute;

        bottom: 15%;
        left: 0;
    }

    .texto {
        color: #797E86;
        font-size: 16px; 
        font-weight: 500;
        line-height: 20px; 
        letter-spacing: -0.16px;
        margin: 8px 0 0;
    
        @media(min-width: 992px){
          margin-top: 16px;
        }
      }

    .video-holder-infra {
        position: relative;  
        z-index: 1;    
        margin-top: 32px; 
        
        @media(max-width: 575px){
            width: calc(100% + 48px);
            margin: 32px -24px 0;  
        }

        @media(min-width: 992px){
            margin-top: 0;
        }
      }
    
      .play-video {
        position: absolute;
        right: 50%;
        top: 50%;
        transform: translate(50%, -50%);
        z-index: 2;
        cursor: pointer;
      }
    
      .bg-video {            
        z-index: 1;        
      }

      .cards-padrao-holder {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        gap: 16px;

        @media(min-width: 992px){
            flex-direction: row;
            align-items: stretch;
            gap: 24px;
            margin-top: 97px;
        }

        .numeros_card {
            height: inherit;
            padding: 24px 24px 42px;

            @media(min-width: 992px){
                width: calc(50% - 12px);
            }

            @media(min-width: 1400px){
                width: calc(25% - 18px);
            }
            
            &_tag {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                width: fit-content;
                padding: 8px 16px;
                height: 35px;
    
                border: 1px solid #FF5F19;
    
                color: #FFF;
           
                font-size: 16px;   
                margin-bottom: 20px;      
            }
    
            .big-info {
                display: flex;
                align-items: center;
                gap: 16px;
                margin-bottom: 20px;
    
                &_number {
                    color: #FF5F19;         
                    font-size: 64px;        
                    font-weight: 700;
                    line-height: 100%;
    
                    .small {               
                        font-size: 24px;             
                    }
                }
            }
    
            .description {
                color: #FFF;   
                font-size: 24px;   
                margin-bottom: 0;
            }
          }
      }     
}

.complexo-sect {
    display: flex;
    align-items: flex-end;
    position: relative;

    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;

    padding: 113px 0 52px;

    @media(min-width: 992px){
        align-items: center;

        padding: 0;
        height: 599px;
    }

    .box {
        background-color: #19191E;
        padding: 40px;
        width: 100%;

        @media(min-width: 992px){
            max-width: 587px;
        }

        &_title {
            color: #FF5F19;           
            font-size: 24px;       
            font-weight: 700;
            margin-bottom: 16px;

            @media(min-width: 992px){
                font-size: 36px;
            }
        }

        &_desc {
            color: #FFF;
            font-family: 'margem-rounded', sans-serif;
            font-size: 14px;         
            font-weight: 500;
            line-height: 20px;
            letter-spacing: -0.14px;
            margin-bottom: 0;

            @media(min-width: 992px){
                font-size: 16px;         
                font-weight: 400;
            }

            & + .box_desc {
                margin-top: 16px;
            }
        }
    }
}

.conheca-numeros_sect--oleo {
    .cards-padrao-holder {
        .swiper-slide {
            .numeros_card {
                padding-left: 24px;
                padding-right: 24px;
            }
        }        
    }
}

.conheca-numeros_sect--lista {
    .cards-padrao-holder {

        .swiper-slide {
            .numeros_card {
                padding: 24px 24px 40px;
                justify-content: flex-start;

                .title {
                    color: #FF5F19;          
                    font-size: 24px;      
                    font-weight: 700;   
                    margin-bottom: 16px;
                    
                    @media(min-width: 992px){
                        margin-bottom: 24px;
                    }
                }

                .links-list {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                    color: white;

                    &_link {                     
                        font-family: 'margem-rounded', sans-serif;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 20px;
                        letter-spacing: -0.16px;
                        text-decoration: none;

                        transition: 0.3s ease;

                        &:hover {
                            color: #FF5F19;
                            text-decoration: underline;
                        }
                    }
                }
            }
        }       
    }
}

.fale-conosco_sect-oleo-base {
    .container {
        .flutuante_img {
            bottom: 10%;
        }
    }   
}

.oleo-parceiros-sect {
    padding-bottom: 80px;
    text-align: center;

    .text {
        color: #39414C;
        font-family: 'margem-rounded', sans-serif;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px; 
        letter-spacing: -0.16px;
        margin: 16px 0 40px;
    }

    .parceiros-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 38px;

        @media(min-width: 992px){
            flex-direction: row;
            gap: 126px;
        }
    }
}