.header-parceiro {
    .bottom_bar {
        .header-links_nav {
            .lists_wrapper {
                .header-links_list {
                    &:last-child {
                        margin: 0;
                        padding: 0;
                        border: 0;
                    }
                }
            }
        }
    }
}

.banner-parceiro {   
    text-align: center;

    @media(min-width: 992px){     
        text-align: initial;       
    }

    .iconic-banner {
        max-width: 242px;

        @media(min-width: 992px){
            max-width: initial;
        }
    }
}

.conheca-numeros_sect-parceiro {
    padding: 0 0 47px;

    @media(min-width: 992px){
        padding-bottom: 80px;;
    }

    .conheca-numeros_carousel {
        margin-top: 32px;

        @media(min-width: 992px){
            margin-top: 24px;
        }

        .numeros_card {
            justify-content: center;
            background-color: $laranja;
            min-height: 346px;

            &::after {
                background: linear-gradient(90deg, #000 0%, rgba(255, 255, 255, 0.00) 100%);
            }

            .intro {
                color: white;

                .small_desc {
                    font-size: 24px;
                    font-style: normal;
                    margin-bottom: 0;
                }
            }

            .desc {
                font-size: 32px;             
                line-height: normal;
            }
        }
    }
}

.image-text_sect {
    overflow: hidden;
    position: relative;
    padding-bottom: 40px;

    @media(min-width: 992px){
        padding-bottom: 12px;
    }

    .flutuante {
        position: absolute;
        left: 0;
        bottom: 100px;       
    }

    .image-text_wrapper {
        position: relative;
        z-index: 2;

        @media(min-width: 992px){
            display: flex;
            align-items: center;
            gap: 35px;     
        }

        &.reverse {
            @media(min-width: 992px){
                flex-direction: row-reverse;
            }
        }

        + .image-text_wrapper {
            margin-top: 48px;

            @media(min-width: 992px){                
                margin-top: 65px;
            }
        }

        .content {
            margin-bottom: 32px;

            @media(min-width: 992px){
                margin-bottom: 0;
            }
        }

        .titulo {
            margin-bottom: 16px;
        }

        .desc {
            color: $cores-neutras-cinza-7;           
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: -0.16px;

            + .desc {
                margin: 16px 0 0;
            }
        }
    }
}

.simulador-compras_sect {
    overflow: hidden;
    position: relative;
    padding: 48px 0;

    @media(min-width: 992px){
        padding: 77px 0 59px;
    }

    .flutuante {
        position: absolute;
        bottom: 71px;
        left: 0;
        
        @media(min-width: 992px){
            bottom: initial;
            left: initial;
            top: 0;
            right: 0;
            max-width: 500px;
        }

        @media(min-width: 1200px){
            max-width: initial;
        }
    }

    .container {
        position: relative;
        z-index: 2;

        @media(min-width: 992px){
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 80px;
        }

        @media(min-width: 1200px){        
            gap: 128px;
        }

        .content {
            margin-bottom: 32px;

            @media(min-width: 992px){
                max-width: 484px;
                margin-bottom: 0;
            }

            @media(min-width: 1600px){
                max-width: initial;
            }

            .titulo {
                margin-bottom: 24px;
                color: $laranja;

                @media(min-width: 992px){
                    margin-bottom: 16px;
                }
            }

            .desc {
                color: $cinza-escuro;           
                font-size: 16px;          
                font-weight: 500;
                line-height: 20px;
                letter-spacing: -0.16px;
                margin-bottom: 0;

                + .desc {
                    margin-top: 16px;
                }
            }
        }

        .image {
            display: block;
            margin: 0 auto;

            @media(min-width: 992px){
                margin: initial;
            }
        }

        .link-padrao-laranja {
            display: flex;       
            width: max-content;     
            margin: 32px auto 0;  

            @media(min-width: 992px){
                margin: 28px 0 0; 
            }
        }
    }
}

.fale-conosco_sect-parceiro {
    position: relative;
    padding-bottom: 48px;

    @media(min-width: 992px){
        padding-bottom: 92px;
    }

    .container {
        position: initial;

        .flutuante_img {
            left: 0;
            bottom: 25%;
        }
    }
}

.como-funciona{
    overflow: hidden;
    padding-top: 40px;
    padding-bottom: 40px;
    position: relative;
    z-index: 1;

    @media(min-width: 992px) {
        padding-top: 82px;
        padding-bottom: 167px;    
    }

    .flutuante-como-funciona{
        width: 100%;
        position: absolute;
        top: 160px;
        left: 0;
        z-index: -1;

        @media(min-width: 992px){
            top: auto;
            bottom: 0;
        }
    }


    .titulo{
        margin-bottom: 20px;
        text-align: center;

        @media(min-width: 992px) {
            text-align: left;
            margin-bottom: 16px;
        }
    }

    .paragrafo{
        color: #797E86;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px; 
        letter-spacing: -0.16px;
        margin-bottom: 60px;

        @media(min-width: 992px) {
            margin-bottom: 24px;
        }
    }

    .content-parceiro{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        background-color: #19191E;
        padding: 32px 24px;

        @media(min-width: 992px) {
            flex-direction: row;
            gap: 20px;
            padding: 48px;
        }

        &_img{
            height: 60px;

            @media(min-width: 992px) {
                height: 84px;
            }
        }

        &_paragrafo{
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            color: white;
            margin-bottom: 0;
            text-align: center;

            @media(min-width: 992px) {
                font-size: 24px;
                text-align: left;
            }
        }
    }
}

.sect_mais-beneficios {
    .sect_transformar {
        background-color: #19191E;
        @media (min-width: 992px) {
            height: 475px;
        }

        .grafismo-laranja {      
            @media (min-width: 992px) {
              height: 475px;
              background-image: url('../img/grafismo-laranja-4.png');
              background-size: cover;
            }
        }

        .box-laranja {
            padding: 71px 0;

            @media (min-width: 992px) {
                padding: 47px 49px;
                margin-top: 0;
            }
        }

        .texto-transformar {
            @media (min-width: 992px) {
                margin-left: 30px;
            }

            @media (min-width: 1200px) {
                margin-left: 53px;
                max-width: 451px;
            }
        }
    }
}

.como-funciona_carousel{
    margin-top: 32px;
    margin-bottom: 40px;
    overflow: visible !important;

    @media(min-width: 992px) {
        overflow: hidden !important;
    }
    
    .swiper-slide {
        height: auto;
    }

    .card-como-funciona {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        background-color: $preto;
        padding: 28px 24px 40px;
        font-family: 'versos', sans-serif;

        @media(min-width: 992px) {
            padding: 42px 32px 45x;
        }   
    
        &::after {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 12px;
            background: linear-gradient(90deg, #FC4E00 0%, rgba(255, 255, 255, 0.00) 100%);     
        }
        
        .intro { 
            
            .card-image{
                height: 75px;
                margin-bottom: 30px;

                @media(min-width: 992px) {
                    height: 114px;
                    margin-bottom: 40px;
                }  
            }

            .text-container{
                display: flex;
                gap: 4px;

                @media(min-width: 992px){
                    gap: 8px;
                }
                
                .number{
                    position: relative;
                    top: -20px;
                    color: #FC4E00;
                    font-size: 36px;
                    font-weight: 700;
                    line-height: normal;
                    margin-bottom: 0;

                    @media(min-width: 992px){
                        font-size: 56px;
                    }
                }
                .texto{
                    color: white;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: normal;
                    margin-bottom: 0;

                    @media(min-width: 992px){
                        font-size: 24px;
                    }
                }
            }
        }
    }
}

.section-material{
    height: 475px;
    background-color: #E5E6E7;
    background-position: left top;
    background-size: 42.5% 100%;
    background-repeat: no-repeat;
    margin-bottom: 100px;
    display: none;

    @media(min-width: 992px){
        display: flex;
    }

    &.programa-performance {
        display: flex;  
        
        @media(max-width: 991px){
            height: 100%;
            background-size: 100% 50%;

            .box-image-programa-performance {
                .img-book {
                    transform: translate(0);
                }
            }   

            .box-texto {
                padding-top: 56px;
                padding-bottom: 56px;

                .titulo {
                    font-size: 24px;
                }
            }        
        }         
       
    }

    .box-image{
        position: relative;

        &-programa-performance {

            .img-book {
                transform: translateY(32px);
            }          
        }
    }
    .box-texto{
        display: flex;
        flex-direction: column;
        justify-content: center;

        .titulo{
            color: #19191E;
            font-size: 36px;
            font-weight: 700;
            line-height: normal;
            margin-bottom: 24px;
        }

        .paragrafo{
            color:  #39414C;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: -0.16px;
            margin-bottom: 24px;
        }

        .btn-link {
            width: max-content;
            font-family: 'versos';
            font-size: 16px;
            font-weight: 400;
            color: $laranja;
            text-decoration: none;
            display: inline-block;
            margin-bottom: 0;
            transition: all ease-in-out .3s;
            position: relative;
            cursor: pointer;
        
            &:hover {
                color: #CD3C00;
            }
        
            &::after {
                content: "";
                position: absolute;
                left: 0;
                bottom: 0;
                background: linear-gradient(90deg, #FF5F19 0%, rgba(255, 255, 255, 0.00) 100%);
                width: 100%;
                height: 2px;
                display: inline-block;
            }
        }
    }
}

.sect_elite {
    .sect_transformar {
        background-color: #19191E;
        @media (min-width: 992px) {
            height: 522px;
        }

        .grafismo-laranja {      
            @media (min-width: 992px) {
              height: 522px;
              background-image: url('../img/grafismo-laranja-4.png');
              background-size: cover;
            }
        }

        .box-laranja {
            padding: 48px 0;

            @media (min-width: 992px) {
                padding: 31px 39px;
                margin-top: 0;
                max-width: 383px;
            }

            @media (min-width: 1200px) {
                margin-left: 63px;
            }
        }

        .box-padrao {
            position: relative;
            z-index: 2;
            width: 100%;
            padding: 33px 0;

            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 50%;
                width: 100vw;
                height: 100%;
                background-color: inherit;
                transform: translateX(-50%);
                z-index: -1;

                @media (min-width: 992px) {
                    content: none;
                }
            }

            @media (min-width:992px) {
                padding: 28px 36px;
                width: fit-content;
            }

            .texto {
                color: #FFF;
                font-size: 14px;
                font-weight: 500;
                line-height: 20px; 
                letter-spacing: -0.14px;
                margin-bottom: 0;

                @media (min-width: 992px) {
                    font-size: 16px;
                    letter-spacing: -0.16px;
                }
            }
        }

        .img-mapa {
            margin: 32px 0;
            display: flex;
            justify-content: center;

            @media (min-width: 992px) {
                margin-left: 23px;
            }
            
        }
    }
}

.marcas-parceiro{
    text-align: center;
    padding-bottom: 48px;
  
    @media(min-width: 992px){
      padding-bottom: 8px;
    }
  
    .marcas{
      
        @media (min-width: 768px){
            display: flex;
            padding-left: 8px;
            padding-right: 8px;
            column-gap: 6px;
        }
    }

    .marca{
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-direction: column;
      height: 320px;
      overflow: hidden;
  
        @media (min-width: 768px){
            flex: 1;
            height: 550px;
        }
  
        .bg{
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
        }
  
        .info{
            position: relative;
            z-index: 3;
            max-width: 90%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            height: 100%;
    
            @media (min-width: 768px){
            max-width: 100%;
            }

            .logo {
                transform: translateX(0);
                margin-top: 68px;
                max-width: 62%;

                @media (min-width: 768px) {
                    margin-top: 200px;
                    max-width: 100%;
                }

                @media (min-width: 992px) {
                    margin-top: 140px;
                }
                
            }

            .lubrificantes {
                max-width: 300px;
                margin-bottom: 25px;

                @media (min-width: 768px){
                    max-width: 350px;
                    margin-bottom: 40px;
                }

                @media (min-width: 992px) {
                    max-width: initial;
                }
            }
        }
  
        &.lubrificantes{
  
            .flutuante-1,
            .flutuante-2,
            .flutuante-3{
            position: absolute;
            top: -30px;
            left: 0;
            scale: 0.6;
            transform-origin: top left;
            transition-property: all;
            transition-timing-function: cubic-bezier(0.68, 0.09, 0.36, 0.91);
            transition-duration: 0.4s;
    
            @media (min-width: 768px){
                top: 0;
                scale: 1;
            }
            }
    
            .flutuante-1{
            z-index: 4;
            }
    
            .flutuante-2{
            z-index: 3;
            }
    
            .flutuante-3{
            z-index: 2;
            }
    
            .bg{
            background-color: $amarelo;
            }
    
        }
    
        &.texaco{
            .bg{
            background-color: #000;
            }
        }
    }
}
