.box-padrao {
  width: fit-content;
  padding: 24px;

  .text {
    font-size: 16px; 
    line-height: normal;
    margin-bottom: 0;

    & + .text {
      margin-top: 16px;
    }
  }

  &.lg {
    padding: 40px 24px;

    @media(min-width: 992px) {
      padding: 48px 40px;
    }
  }

  &.laranja {
    background-color: $laranja;
    color: white;
  }

  &.preto {
    background-color: $preto;
    color: white;
  }

  &.cinza {
    background-color: $cinza;
    color: $preto;
  }

  &.cinza-escuro {
    background-color: $cinza-escuro;
    color: white;
  }
}