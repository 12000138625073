/* ==========================================================================
   Botoes
   ========================================================================== */

.btn {
   display: inline-flex;
   align-items: center;
   justify-content: center;
   height: 54px;
   padding: 0 32px;
   font-size: 16px;
   font-style: normal;
   font-weight: 500;
   line-height: 20px;
   text-align: center;
   border-radius: 0;
   transition: all ease-in-out .3s;

   &-laranja {
      background-color: $laranja;
      color: $preto;

      &:hover {
         background-color: #CD3C00;
      }
   }

   &-roxo {
      background-color: $roxo;
      color: white;

      &:hover {
         background-color: $roxo-escuro;
         color: white;
      }
   }

   &-laranja-branco {
      background-color: $laranja;
      color: white;

      &:hover {
         background-color: #CD3C00;
      }
   }

   &.full-mobile{
      width: 100%;

      @media (min-width: 768px){
         width: auto;
      }
   }
}