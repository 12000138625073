.banner-performance {

    .container {
        @media(min-width: 992px){
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .play_btn {
            display: flex;
            align-items: center;
            gap: 11px;
            background-color: transparent;
            outline: none;
            border: 0;
            color: white; 
            transition: 0.3s ease;
            margin: 40px auto 0;

            @media(min-width: 992px){
                margin: 0 120px 0 0;
            }

            &:hover {
                opacity: 0.8;
            }
            
            .text {
                font-size: 16px;         
                font-weight: 500;
                line-height: 20px; 
            }
        }
    }    
}

.modal_iframe {    

    .modal-dialog {
        width: 100%;
        padding: 24px;
        max-width: inherit;

        @media(min-width: 992px){
            max-width: 70vw;
            padding: 0;
        }

        .modal-content {
            position: relative;
            padding-top: 56.25%;
            transform: none;
            background-color: transparent;
            border: 0;

            .btn-close {
                position: absolute;
                right: 0;
                top: -30px;
                opacity: 1;
                transition: 0.3s ease;

                &:hover {
                    opacity: 0.8;
                }
            }

            .modal_video {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
}

.sect_mais-beneficios-performance {
    .sect_transformar {
        .box-laranja {
            @media(min-width: 992px){
                padding: 47px 41px;
            }
        }
    }
    .texto-transformar {
        padding-bottom: 24px;
    }

    .lista-transformar {
        color: white;
        padding-left: 24px;
        margin-bottom: 48px;
        list-style-image: url('../img/icone-list-check-laranja.svg');

        @media(min-width: 992px){
            margin-bottom: 0;
            padding-left: 80px;
        }

        .list_item {
            font-size: 14px;        
            font-weight: 500;
            line-height: 20px; 
            letter-spacing: -0.14px;

            + .list_item {
                margin-top: 22px;
            }
        }
    }
}

.programa-pilares_sect {
    overflow: hidden;
    position: relative;
    padding-top: 40px;

    @media(min-width: 992px){
        padding-left: 56px;
    }

    .flutuante {
        display: none;
        position: absolute;
        top: 30px;
        right: 0;

        @media(min-width: 1200px){
            width: 250px;
            display: block;
        }

        @media(min-width: 1400px){
            width: initial;     
        }
    }

    .titulo {
        text-align: center;
        margin-bottom: 37px;

        @media(min-width: 992px){
            margin-bottom: 56px;
        }
    }

    .pilares_wrapper {
        position: relative;
        z-index: 2;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 32px;

        @media(min-width: 992px){
            flex-direction: row;
            align-items: flex-start;
            gap: 52px;
        }

        .pilares_card {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 16px;

            @media(min-width: 992px){
                gap: 20px;
            }

            .card_title {
                color: #19191E;
                text-align: center;            
                font-size: 18px;         
                font-weight: 700;
                line-height: normal;
                margin-bottom: 0;

                @media(min-width: 992px){
                    font-size: 24px;    
                }
            }

            .text {
                display: block;
                color: #797E86;            
                font-size: 16px;        
                line-height: normal;
                margin-top: 7px;
                text-align: center;
            }            
        }
    }
}

.programa-solucao-completa_sect-performance {
    overflow: hidden;
    padding-top: 87px;

    @media(min-width: 992px){
        padding-top: 123px;
    }

    .titulo {
        margin-bottom: 29px;

        @media(min-width: 992px){
            margin-bottom: 41px;
        }
    }

    .container {
        position: relative;  
        padding-bottom: 135px;   
        
        @media(min-width: 992px){
            padding-bottom: 63px;   
        }
    }

    .swiper {
        overflow: visible;

        @media(min-width: 992px){
            overflow: hidden;
        }

        .swiper-slide {
            background-color: #F2F2F3;
            min-height: 230px;
            width: 283px;
            padding: 32px 23px 29px 32px;
            border-bottom: 6px solid;
            border-image: linear-gradient(90deg, #EA5B0B 0%, rgba(255, 255, 255, 0.00) 100%);
            border-image-slice: 1;
            height: 6px;
        
            @media (min-width: 992px) {
              width: 260px;
              height: 346px;
              padding: 48px 40px 72px;
            }      
         
        
            .img-box {
                max-width: 73px;
                margin-bottom: 32px;

              @media (min-width: 992px) {
                max-width: initial;
                margin-bottom: 48px;
              }
            }
            
            .titulo-box {
              color: $preto;
              font-size: 16px;
              font-weight: 700;
              line-height: 1;
              margin-bottom: 0;

              @media (min-width: 992px) {
                font-size: 24px;
              }
            }
          }
    }
}

.programa-ficha-tecnica_sect {
    background-image: url('../img/bg-ficha-tecnica.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    padding: 48px 0 38px;

    color: #797E86;

    @media(min-width: 992px){
        padding: 30px 0;
    }

    .container {
        @media(min-width: 992px){
            display: flex;
            align-items: center;
            gap: 60px;
        }

        .content {
            margin-bottom: 34px;

            @media(min-width: 992px){
                margin-bottom: 0;
            }

            .titulo {
                color: #39414C;
                margin-bottom: 16px;
            }

            .text {           
                font-size: 16px;       
                font-weight: 500;
                line-height: 20px;
                letter-spacing: -0.16px;
                margin-bottom: 0;

                & + .text {
                    margin-top: 24px;

                    @media(min-width: 992px){
                        margin-top: 16px;
                    }
                }
            }

            .ficha-tecnica_list {          
                padding-left: 24px;
                margin: 24px 0 0;
                list-style-image: url('../img/icone-list-check-laranja.svg');
        
                @media(min-width: 992px){
                    margin-top: 52px;
                    padding-left: 28px;
                }
        
                .list_item {
                    font-size: 16px;        
                    font-weight: 500;
                    line-height: 20px; 
                    letter-spacing: -0.16px;
        
                    + .list_item {
                        margin-top: 16px;
                    }
                }
            }
        }
    }
}

.programa-calculadora_sect {
    position: relative;
    padding-bottom: 32px;

    @media(min-width: 992px){
        padding-bottom: 87px;
    }

    .flutuante {
        position: absolute;
        left: 0;
        bottom: 100px;

        @media(min-width: 992px){
            width: 500px;
        }

        @media(min-width: 1600px){
            width: initial;
        }
    }

    .container {
        display: flex;
        flex-direction: column-reverse;
        gap: 57px;

        @media(min-width: 992px){
            flex-direction: row;
            align-items: center;
            gap: 24px;
        }

        .image {
            position: relative;
            z-index: 2;   
            transition-delay: 0.4s;       
        }

        .content {
            .titulo {
                margin-bottom: 16px;
            }

            .text {
                color: #797E86;        
                font-size: 18px;             
                font-weight: 500;
                line-height: 20px;
                letter-spacing: -0.18px;
                margin-bottom: 0;

                @media(min-width: 992px){
                    font-size: 16px;     
                }

                & + .text {
                    margin-top: 16px;
                }
            }
        }
    }
}

.programa-lubewatch_sect {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    background-color: #FF5F19;
    color: white;
    
    .area-texto {
      padding: 40px 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      position: relative;
  
      @media (min-width: 992px) {
        height: 550px;
        padding: 0 24px 0 0;
      }

      @media (min-width: 1200px) {
        height: 500px;
      }
    }
  
    .image_bg {
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      height: 290px;
      width: 100%;
  
      @media (min-width: 992px) {
        height: 550px;
        width: 50%;
        top: 0;
        right: 0;
        position: absolute;
      }

      @media (min-width: 1200px) {
        height: 500px;
      }
    }  

    .logo {
        margin-bottom: 26px;
    }
  
    .text {          
        font-size: 16px;    
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.16px;
        margin-bottom: 0;

        & + .text {
            margin-top: 24px;

            @media(min-width: 992px){
                margin-top: 16px;
            }
        }
    } 
    
    .info-box {
        display: flex;
        align-items: center;
        gap: 11px;

        width: 100%;
        padding: 14px;

        background-color: #19191E;
        color: #FF5F19;

        margin-top: 24px;

        @media(min-width: 992px){
            margin-top: 32px; 
        }

        .box_text {        
            font-size: 16px;         
            font-weight: 500;
            line-height: 20px;
            letter-spacing: -0.18px;
            margin-bottom: 0;

            @media(min-width: 992px){
                font-size: 18px;    
            }
        }
    }
  }

  .programa-beneficios-lubewatch_sect {
    overflow: hidden;
    background-color: #19191E;
    color: white;
    padding-top: 40px;

    @media(min-width: 992px){
        padding-top: 93px;  
    }

    .titulo {
        margin-bottom: 32px;     
    }

    .container {
        position: relative;

        padding-bottom: 120px;

        @media(min-width: 992px){
            padding-bottom: 156px;  
        }
    }

    .swiper {
        overflow: visible;

        @media(min-width: 992px){
            overflow: hidden; 
        }
    }

    .swiper-slide {
        height: auto;
      }
  
      .numeros_card {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        background-color: #FF5F19;
        padding: 18px 24px 38px;
        font-family: 'versos', sans-serif;

        color: white;
  
        @media(min-width: 992px) {
          padding: 22px 24px 44px;
        }   
        
        &::after {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 12px;
          background: linear-gradient(90deg, #000 0%, #EA5B0B 100%);
        }  
        
        .card_title {      
            text-align: center;        
            font-size: 20px;       
            font-weight: 700;
            line-height: normal;
            margin: 8px 0 24px;
        }
  
        .desc {      
            font-size: 12px;        
            line-height: normal;
            margin-bottom: 0;

            @media(min-width: 992px) {
                font-size: 14px;     
            }   
        }
      }
  }

