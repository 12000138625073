/*!
 * Tipografia
 * Iconic Lubrificantes
 * Desenvolvido por Fattoria em: 12/11/2020
 */

// Margem Rounded

@font-face {
    font-family: 'margem-rounded';
    src: url('../fonts/margemrounded-medium.woff2') format('woff2'),
    url('../fonts/margemrounded-medium.woff') format('woff');
    font-weight: 500;
}

@font-face {
    font-family: 'margem-rounded';
    src: url('../fonts/margemrounded-medium-italic.woff2') format('woff2'),
    url('../fonts/margemrounded-medium-italic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

// Versos

@font-face {
    font-family: 'versos';
    src: url('../fonts/versos-regular.woff2') format('woff2'),
    url('../fonts/versos-regular.woff') format('woff');
    font-weight: 400;
}

@font-face {
    font-family: 'versos';
    src: url('../fonts/versos-bold.woff2') format('woff2'),
    url('../fonts/versos-bold.woff') format('woff');
    font-weight: 700;
}

// Icones

@font-face {
    font-family: 'iconic icones';
    src:  url('../fonts/iconic-icones.eot?6uz4fd');
    src:  url('../fonts/iconic-icones.eot?6uz4fd#iefix') format('embedded-opentype'),
    url('../fonts/iconic-icones.ttf?6uz4fd') format('truetype'),
    url('../fonts/iconic-icones.woff?6uz4fd') format('woff'),
    url('../fonts/iconic-icones.svg?6uz4fd#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.icones{
    font-family: 'iconic icones' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icone-chevron-left:before {content: "\e900";}
.icone-chevron-down:before {content: "\e901";}
.icone-olho:before {content: "\e902";}
.icone-download:before {content: "\e903";}
.icone-localizacao:before {content: "\e904";}
.icone-chevron-right:before {content: "\e905";}