.header {
    .top_bar {
        display: none;

        @media(min-width: 992px) {
            display: block;
            position: relative;            
            background-color: $cinza-escuro;
        }

        .container {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            
        }

        .top_bar_list {
            display: flex;
            align-items: center;           
            gap: 24px;
            margin-bottom: 0;
            padding: 6px 0;  
            
            .list-item-language{
                display: flex;
                align-items: center;
                gap: 10px;

                .language{
                    transition: all .4s linear;
                }

                &:has(.language.d-none){
                
                    .list_link:last-child{
                        pointer-events: none;
                        cursor: none;

                        &:hover {
                            color: white;
                           
                        }
                    }
                } 
            }

            .list_link {
                display: inline-flex;
                align-items: center;
                gap: 8px;
                font-size: 14px;           
                font-weight: 500;
                line-height: normal;
                color: white;
                text-decoration: none;
                transition: 0.3s ease;

                &:hover {
                    color: $laranja;
                }

                &.active {
                    color: $laranja;
                }

                &.btn-language{
                    background-color: transparent;
                    border: 0;
                    outline: 0;
                    padding: 0;

                    .icone-global{
                        fill: white;
                        transition: 0.3s ease;

                        &:hover {
                            fill: $laranja;
                        }
                    }
                }
            }  
        }
    }

    .bottom_bar {
        background-color: $preto;
        padding: 24px 0;

        @media(min-width: 992px){
            padding: 31px 0;
        }

        .container {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .logo {
            position: relative;
            z-index: 10;
            max-width: 80px;

            @media(min-width: 992px){
                max-width: initial;
            }
        }

        .menu_btn {
            position: relative;
            z-index: 10;
            display: flex;
            flex-direction: column;
            gap: 4px;
            background-color: transparent;
            outline: none;
            border: 0;
            padding: 0;

            @media(min-width: 992px) {
                display: none;
            }

            .bar {
                width: 22px;
                height: 2px;
                border-radius: 3px;
                background-color: white;
                transition: 0.4s ease;
            }

            &.on {
                .bar1 {
                    transform: rotateZ(45deg) translateY(8px);
                }

                .bar2 {
                    width: 0;
                }

                .bar3 {
                    transform: rotateZ(-45deg) translateY(-9px);
                }
            }
        }

        .header-links_nav {
            position: fixed;           
            z-index: 9;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;           
            background-color: $preto;
            text-align: center;
            padding: 0 24px;
            transform: translateX(-100%);
            transition: 0.6s ease;

            &.on {
                transform: translateX(0);
            }

            @media(min-width: 992px) {
                padding: 0;
                text-align: initial;
                position: relative;
                transform: translateX(0);
            }

            .lists_wrapper {
                height: 100%;
                overflow: auto;
                padding: 100px 0 129px;

                @media(min-width: 992px) {
                    height: initial;
                    overflow: initial;
                    padding: 0;
                }

                .header-links_list {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    gap: 16px;
                    margin: 0;
    
                    @media(min-width: 992px){
                        flex-direction: row;
                        gap: 12px;
                    }
    
                    @media(min-width: 1200px){                  
                        gap: 20px;
                    }

                    @media(min-width: 1400px){                  
                        gap: 24px;
                    }
    
                    &:last-child {
                        margin-top: 24px;
                        padding-top: 24px;
                        border-top: 1px solid $laranja;
                    }
                }
    
                .list_links {
                    position: relative;
                    display: flex;
                    align-items: center;
                    gap: 4px;
                    font-size: 16px;              
                    font-weight: 500;
                    line-height: 20px;
                    color: white;   
                    text-decoration: none;
                    transition: 0.3s ease;  
                    width: fit-content;
    
                    @media(min-width: 992px){
                        font-size: 14px;
                    }
    
                    @media(min-width: 1200px){                  
                        font-size: 16px;
                    }
    
                    .icones {
                        font-size: 12px;
                        margin-top: 1px;
    
                        @media(min-width: 992px){
                            font-size: 10px;
                        }
        
                        @media(min-width: 1200px){                  
                            font-size: 12px;
                        }
                    }
    
                    &::after {
                        content: '';
                        display: block;
                        position: absolute;
                        left: 0;
                        bottom: -4px;
                        height: 2px;
                        transition: 0.3s ease;
                        background: linear-gradient(90deg, $laranja 0%, rgba(255, 255, 255, 0.00) 100%);
                    }
                    
                    &:hover {
                        color: $laranja;
    
                        @media(min-width: 992px) {
                            &::after {
                                right: 0;
                            }
                        }
                    }
    
                    &.active {
                        color: $laranja;
    
                        @media(min-width: 992px) {
                            &::after {
                                right: 0;
                            }
                        }
                    }
                }
            }       
        }
    }

    .language-wrapper{
        position: absolute;
        left: 0;
        bottom: 24px;
        right: 0;   

        &_lista{
            padding: 0;
            display: flex;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            gap: 10px;
            margin: 0;
            list-style-type: none;

            .language-link{
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                color: white;
                

                & > a{text-decoration: none;}

                &.active{
                    color: #FF5F19;
                }
            }
        }
    }
    .acessibilidade_wrapper {        
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;         
        height: 68px;
        background-color: $cinza-escuro;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 56px;

        @media(min-width: 992px) {    
            position: relative;      
            justify-content: flex-end;
            gap: 25px;                 
            overflow: hidden;
            height: 100%;
            width: 0;        
            transition: width 1s ease;            
        }

        .acessibilidade_list {            
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 56px;

            @media(min-width: 992px) {                
                width: 100%;
                min-width: fit-content;
                gap: 25px;
                justify-content: flex-end;
                margin-bottom: 0;               
                margin: 6px 20px;
                padding: 0 20px;
                border-right: 1px solid $cinza;              
            }
        }       

        .btn-acessibilidade {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 3px;
            background-color: transparent;
            outline: none;
            border: 0;
            padding: 0;

            @media(min-width: 992px) {
                width: fit-content;
                flex-direction: row;
                gap: 5px;
                white-space: nowrap;                                      
            }

            .text {
                font-size: 12px;                     
                line-height: 14px;
                color: white;
            }
        }
    }
}