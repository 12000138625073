.modal-padrao{

    .modal-dialog{
        max-width: 630px;
    }
    .modal-content{
        border-radius: 0;
    }
    .modal-header{
        padding: 40px 32px 24px;
        border: 0;
        align-items: flex-start;
    
        .modal-title{
            font-family: 'versos', sans-serif;
            color: $laranja;
            font-size: 36px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }
        .btn-close{
            min-width: 24px;
            min-height: 24px;
            width: 24px;
            height: 24px;
            background-color: transparent;
            background-image: url(../img/icone-close.svg);
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            opacity: 1;
            margin: 0;
            padding: 0;
            transition: all .4s ease;

            &:hover{
                opacity: .7;
            }
        }
    }
    .modal-body{
        padding: 0 32px 48px;

        .line{
            background: #E5E6E7;
            margin-top: 0;
            margin-bottom: 24px;
        }

        .paragrafo{
            color: #303236;
            font-family: 'margem-rounded';
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; 
            letter-spacing: -0.16px;
        }
    }
}

.modal-video {
    .modal-dialog {
        max-width: 800px;                

        .modal-content { 
            background-color: transparent;  
            border: 0;
            
            .modal-header {
                border: 0;
                justify-content: flex-end;
                padding: 8px 0;

                .close_btn {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 32px;
                    background-color: transparent;
                    outline: none;
                    border: 0;
                    color: white;    
                    padding: 0;                

                    &:hover {
                        opacity: 0.8;
                    }
                }
            }

            .modal-body {  
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: center; 
                padding: 0;
                background-color: black;   
                height: 60vh;               
                .video {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}

.modal-saiba-mais {

    .modal-dialog{
        max-width: 620px;
    }
    .modal-content{
        border-radius: 0;
    }
    .modal-header{
        padding: 32px;
        border: 0;
        align-items: flex-start;
    
        .modal-title {
            color:#19191E;
            font-size: 32px;
            font-weight: 700;
            line-height: normal;
            margin-bottom: 0;
            font-family: 'versos', sans-serif;
        }

        .btn-close{
            min-width: 24px;
            min-height: 24px;
            width: 24px;
            height: 24px;
            background-color: transparent;
            background-image: url(../img/icone-close.svg);
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            opacity: 1;
            margin: 0;
            padding: 0;
            transition: all .4s ease;

            &:hover{
                opacity: .7;
            }
        }
    }
    .modal-body{
        padding: 0 32px 32px;

        .line{
            background: #E5E6E7;
            margin-top: 0;
            margin-bottom: 24px;
        }

        .paragrafo {
            color: #797E86;
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: -0.16px;
            margin-bottom: 16px;
        }

        .btn-laranja {
            margin-top: 32px;
            color: white;
            width: 181px;
        }
    }
}