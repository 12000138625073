// Css raiz do projeto

/*!
 *  Iconic Lubrificantes
 * Desenvolvido por Fattoria em: 12/11/2020
 */

 // Config
@import "config/_variaveis.scss";

// Tipografia
@import "_tipografia.scss";

// Geral
@import "geral/_reset.scss";
@import "geral/_medias.scss";
@import "geral/_utilidades.scss";
@import "geral/_animations.scss";
@import "geral/_geral.scss";

// Componentes
@import "componentes/_btns.scss";
@import "componentes/_titulos.scss";
@import "componentes/_header.scss";
@import "componentes/_dropdowns.scss";
@import "componentes/_collapses.scss";
@import "componentes/_carouseis.scss";
@import "componentes/_footer.scss";
@import "componentes/_forms.scss";
@import "componentes/_boxs.scss";
@import "componentes/modal.scss";

// Paginas
@import "paginas/_integridade.scss";
@import "paginas/_ctic.scss";
@import "paginas/_parceiro.scss";
@import "paginas/_performance.scss";
@import "paginas/_lubewatch.scss";
@import "paginas/oleo-base.scss";
































































































