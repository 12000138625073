// Tamanhos
.fs-8{ font-size: $fs-8 !important; }
.fs-10{ font-size: $fs-10 !important; }
.fs-12{ font-size: $fs-12 !important; }
.fs-14{ font-size: $fs-14 !important; }
.fs-16{ font-size: $fs-16 !important; }
.fs-18{ font-size: $fs-18 !important; }
.fs-20{ font-size: $fs-20 !important; }
.fs-22{ font-size: $fs-22 !important; }
.fs-24{ font-size: $fs-24 !important; }
.fs-26{ font-size: $fs-26 !important; }
.fs-28{ font-size: $fs-28 !important; }
.fs-30{ font-size: $fs-30 !important; }
.fs-32{ font-size: $fs-32 !important; }
.fs-34{ font-size: $fs-34 !important; }
.fs-36{ font-size: $fs-36 !important; }
.fs-38{ font-size: $fs-38 !important; }
.fs-40{ font-size: $fs-40 !important; }
.fs-42{ font-size: $fs-42 !important; }
.fs-44{ font-size: $fs-48 !important; }
.fs-52{ font-size: $fs-52 !important; }
.fs-56{ font-size: $fs-56 !important; }
.fs-60{ font-size: $fs-60 !important; }
.fs-62{ font-size: $fs-62 !important; }
.fs-70{ font-size: $fs-70 !important; }
.fs-80{ font-size: $fs-80 !important; }
.fs-85{ font-size: $fs-85 !important; }


.c-laranja {
    color: $laranja !important;
}

.c-cinza-80 {
    color: $cinza-escuro;
}

.c-cinza-b7 {
    color: $cinza-b7 !important;
}

.c-branco {
    color: white !important;
}

.c-cores-neutras-cinza-7 {
    color: $cores-neutras-cinza-7 !important;
}

.bgc-roxo {
    background-color: $roxo !important;
}

.bgc-roxo-escuro {
    background-color: $roxo-escuro !important;
}
.mb-32{margin-bottom: 32px;}