

.footer{
    background-color: $preto;
    color: white;

    .nossas-midias{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 50px 0;
        position: relative;

        @media (min-width: 992px) {
          justify-content: space-between;  
          flex-direction: row;  
        }

        &::after{
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 3px;
            background: linear-gradient(90deg, #EA5B0B 0%, rgba(255, 255, 255, 0.00) 100%);
          }

        .box{
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;

            @media (min-width: 992px) {
                align-items: flex-start;
            }

            .footer-titulo{
                font-family: 'versos', sans-serif;
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                margin-bottom: 24px;

                @media (min-width: 992px) {
                    font-size: 36px;  
                  }
            }
            .media-content{
                display: flex;
                gap: 8px;

                .media-icone{
                    width: 56px;
                    height: 56px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: $laranja;
                }

                &.margin-bottom {
                    margin-bottom: 72px;

                    @media (min-width: 992px) {
                        margin-bottom: 0;
                    }
                }  
            }
        }
    }

    .info{
        padding: 56px 0 64px;

        @media (min-width: 992px) {
            padding: 66px 0 70px;   
          }

        .info-logo{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            @media (min-width: 992px) { 
               align-items: flex-start;
            }

            &_desc{
                @media (max-width: 992px) { 
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                 }
            }

            .logo{
                margin-bottom: 24px;
                width: 142px;

                @media (min-width: 992px) { 
                   width: initial;
                 }
            }

            .endereco{
                display: flex;
                flex-direction: column;
                align-items: center;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
                margin-bottom: 0;

                @media (min-width: 992px) { 
                    align-items: flex-start;
                }
            }
        }

        .lista-links{
            padding: 0;

            .link-item{
                list-style-type: none;
                transition: all .2s ease;
                font-size: 24px;
                line-height: normal;

                .link{
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px;
                    text-decoration: none;
                    text-wrap: nowrap;
                    margin-bottom: 8px;
                    cursor: pointer;
                }

                .link-btn {
                    border: none !important;
                    background-color: transparent !important;
                    outline: none !important;
                    color: white !important;
                    padding: 0 !important;
                    margin: 0 !important;
                    transition: .6s ease !important;
                    font-size: 16px !important;

                    &:hover {
                        color: $laranja !important;
                        background-color: transparent !important;
                    }
                    
                }

                &:hover {                    
                    @media(min-width: 992px){
                        list-style-type: square;
                        color: $laranja;
                        font-size: 24px;
                        line-height: normal;
                    }
                }
            }   

            &--mobile {
                text-align: center;
                margin-top: 32px;

                .link-item {
                    + .link-item {
                        margin-top: 16px;
                    }

                    .link {
                        font-size: 18px;
                    }
                }
            }
        }
    }

    .direitos-iconic{
        padding: 22px 0;
        background-color: $cinza-escuro;

        @media (min-width: 992px) { 
           padding: 24px 0 20px;
        }

        .container{
            display: flex;
            justify-content: center;
            align-items: center;

              @media (min-width: 992px) { 
                justify-content: space-between;
              }
        }

        .texto{
            margin-bottom: 0;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
        }
    }

    .footer-selo-Wrapper{
        display: flex;
        gap: 24px;

        .img-selo{
            object-fit: contain;

            &_quimico{
                width: 64px;

                @media (min-width: 992px) {
                    width: 80px;
                }
            }
            &_glassdoor{
                width: 133px;
            }
        }
    }
}