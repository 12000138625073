/* ==========================================================================
   Títulos
   ========================================================================== */

.titulo {
   font-family: 'versos', sans-serif;
   font-style: normal;
   font-weight: 700;
   line-height: normal;
   margin-bottom: 0;

   &.md {
      font-size: 18px;
   
      @media (min-width: 992px) {
         font-size: 28px;
      }
   }
   
   &.lg {
      font-size: 24px;
   
      @media (min-width: 992px) {
         font-size: 32px;
      }
   }
   
   &.xl {
      font-size: 24px;
   
      @media (min-width: 992px) {
         font-size: 36px;
      }
   }
}
