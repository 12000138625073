.conheca_sect {
  position: relative; 
  width: 100%;
  overflow: hidden;
  padding-bottom: 57px;

  @media (min-width: 992px) {
    height: 569px;
    display: flex;
    align-items: center;
    padding-bottom: 0;
  }

  &.small {
    @media (min-width: 992px) {
      height: 467px;      
    }
  }

  .grafismo-roxo {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 57px;
    background-color: white;
    background-image: url('../img/img-grafismo-roxo-mobile.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @media (min-width: 992px) {
      height: 690px;
      width: 41%;     
      background-image: url('../img/img-grafismo-roxo.svg');  
      background-size: cover;
      background-position: 50% 50%;   
    }
  }

  .grafismo-laranja {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 57px;
    background-color: white;
    background-image: url('../img/grafismo-ctic-mobile.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @media (min-width: 992px) {
      height: 690px;
      width: 41%;     
      background-image: url('../img/grafismo-ctic.png');  
      background-size: cover;
      background-position: 50% 50%;   
    }
  }

  .box-roxo {
    background-color: $roxo;
    padding: 57px 0;
    position: relative;
    z-index: 2;  
    margin: 57px 0;   

    @media (min-width: 992px) {
      padding: 47px 49px;
      margin: 0;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      width: 100vw;
      height: 100%;
      background-color: inherit;
      transform: translateX(-50%);
      z-index: -1; 

      @media (min-width: 992px) {
        content: none;
      }      
    }

  }

  .box-laranja {
    background-color: #FF5F19;
    padding: 57px 0;
    position: relative;
    z-index: 2;  
    margin: 57px 0;   

    @media (min-width: 992px) {
      padding: 47px 49px;
      margin: 0;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      width: 100vw;
      height: 100%;
      background-color: inherit;
      transform: translateX(-50%);
      z-index: -1; 

      @media (min-width: 992px) {
        content: none;
      }      
    }

    &::after {
      @media (min-width: 992px) {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 4px;
        background: linear-gradient(90deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);
      }
    }

  }

  .logo {
    margin-bottom: 32px;
    max-width: 346px;

    @media(min-width: 992px) {
      max-width: initial;
    }
  }

  .texto {
    color: $cores-neutras-cinza-7;  
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.16px;
    margin-bottom: 0;
    
    & + .texto {
      margin-top: 16px;
    }
  }
}

.paixao-inovacao_sect {
  background-color: #FF5F19;
  position: relative;

  @media (min-width: 992px) {
    height: 500px;
  }

  .container {
    @media(min-width: 992px) {
      height: 100%;
      display: flex;
      align-items: center;
    }
  }

  .area-texto {
    margin: 48px 0;

    @media (min-width: 992px) {
      margin: 0;
    }
  } 

  .titulo {
    color: white;
    text-align: center;
    font-size: 32px;

    @media(min-width: 992px) {
      font-size: 48px;
      text-align: initial;
    }
  }

  .video-holder-susten {
    position: relative;
    width: 100%;
    height: 390px;
    z-index: 1;

    @media (min-width: 992px) {
      height: 500px;
      position: absolute;
      right: 0;
      top: 0;
      width: 50%;
    }
  }

  .play-video {
    position: absolute;
    right: 50%;
    top: 50%;
    transform: translate(50%, -50%);
    z-index: 2;
    cursor: pointer;
  }

  .bg-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }
}

.sect_certificacoes {
  &.ctic {
    overflow: visible;
    position: relative;
    padding: 56px 0;
    background-color: $cinza-f2;

    @media(min-width: 992px) {
      padding: 107px 0;
    }

    .flutuante_image {
      display: none;
      position: absolute;
      top: 89px;
      right: 0;

      @media(min-width: 992px) {
        display: block;
      }
    }

    .container {
      position: relative;
      z-index: 2;
    }
  }
}


.conhecimento-local_sect {  
  padding-bottom: 45px;
  background-color: $cinza-f2;

  @media(min-width: 992px) {
    padding-bottom: 137px;
  } 

  .content {
    width: 100%;
    position: relative;

    @media(min-width: 992px){
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 500px;
    }

    .box-padrao {
      position: relative;
      z-index: 2;   

      &.preto {
        max-width: 223px;
        padding-top: 23px;
        padding-bottom: 23px;

        @media(min-width: 992px) {
          margin-left: 78px;
          max-width: 306px;
          padding-top: 25px;
          padding-bottom: 25px;
        }

        @media(max-width: 991px) {
          position: absolute;
          bottom: 24px;
          left: 21px;         
        }
      }

      &.cinza {
        width: 100%;

        @media(min-width: 992px) {
          margin-left: 132px;
          max-width: 455px;
        }
      }
    }

    .bg_holder {
      position: relative;
      width: 100%;

      &::after {
        content: '';
        display: block;
        padding-bottom: 75%;

        @media(min-width: 992px) {
          display: none;
        }
      }

      @media(min-width: 992px) {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;        
      }

      .grafismo {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 32%;
        background-image: url('../img/grafismo-portal.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }

      .image {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 68%;
        background-image: url('../img/img-conhecimento.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
  }
}

.referencia_sect {
  overflow: hidden;
  background-color: $cinza-f2;
  padding-bottom: 70px;

  @media(min-width: 992px){
    padding-bottom: 100px;
  }

  .container {
    @media(min-width: 992px){
      display: flex;
      align-items: center;
      gap: 16px;
    }
  }

  .content {
    margin-bottom: 32px;

    @media(min-width: 992px){
      width: 50%;
      margin-bottom: 0;
    }

    .text {
      color: $cores-neutras-cinza-7;      
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; 
      letter-spacing: -0.16px;
      margin: 32px 0 0;
    }
  }

  .referencia_carousel {
    overflow: visible;

    @media(min-width: 992px){
      width: 50%;
    }  

    .swiper-slide {
      @media(max-width: 991px) {
        width: fit-content;
      }
    }
    
    .swiper-pagination {
      position: relative;
      display: flex;  
      margin-top: 24px; 
      justify-content: center;     

      @media(min-width: 992px){               
          align-items: center;   
          justify-content: flex-start;      
      }

      .swiper-pagination-bullet {
        width: 10px;
        height: 10px;
        border-radius: initial;
        background-color: $cinza;
        opacity: 1;

        &-active {
          background-color: #FF5F19;
        }
      }
  }

    .referencia_card {
      position: relative;
      width: 282px;
      height: 371px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      transition: .2s ease-in;

      @media(min-width: 992px) {
        width: 100%;
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 8px;
        background: linear-gradient(90deg, #FF5F19 0%, rgba(255, 255, 255, 0.00) 100%);
      }

      &.off {
        @media(min-width: 992px){
          transform: scale(0.3);
          opacity: 0;
          z-index: -1;
        }
      }
    }
  }
}

.lubewatch_sect {
  position: relative;
  background-color: $cores-neutras-cinza-2;

  @media(min-width: 992px) {
    display: flex;
    align-items: center;
    height: 631px;
  }

  .grafismo {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 57px;
    background-image: url('../img/img-grafismo-cinza.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @media(min-width: 992px) {
      height: 100%;
      left: initial;
      right: 0;
      width: 42%;
    }
  }

  .container {
    display: flex;
    align-items: center;
    flex-direction: column-reverse;

    @media(max-width: 991px) {
      padding: 0;
      max-width: initial;      
    }

    @media(min-width: 992px) {
      flex-direction: row;
      gap: 34px;
    }
  }

  .boxs_wrapper {
    position: relative;
    z-index: 2;
    width: 100%;
    padding-top: 57px;

    @media(min-width: 992px){
      width: initial;
      padding-top: 0;
    }

    .box-padrao {
      width: 100%;

      &.laranja {
        display: flex;
        align-items: center;
        justify-content: center;
        
        @media(min-width: 992px){
          max-width: 408px;
        }
      }

      &.preto {
        
        @media(min-width: 992px){
          max-width: 440px;
          margin-left: 46px;
        }

        .texto {
          font-family: 'versos', sans-serif;
          font-size: 16px;          
          font-weight: 700;
          margin-bottom: 0;

          @media(min-width: 992px){
            font-size: 18px; 
          }
        }
      }
    }
  }

  .content {
    position: relative;
    z-index: 2;
    padding: 47px 24px 54px;

    @media(min-width: 992px) {
      width: 50%;
      padding: 0;
    }

    .text {
      color: $cores-neutras-cinza-7;   
      font-size: 16px;     
      font-weight: 500;
      line-height: 20px; 
      letter-spacing: -0.16px;
      margin: 24px 0;
    }
  }
}

.link-padrao-laranja {
  position: relative;
  text-decoration: none;
  color: $laranja;
  font-family: 'versos', sans-serif;
  font-size: 16px; 
  line-height: normal;
  transition: 0.3s ease;

  &:hover {
    opacity: 0.8;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 5px;
    left: 0;
    bottom: -9px;
    background: linear-gradient(90deg, #FF5F19 0%, rgba(255, 255, 255, 0.00) 100%);
  }
}

.servicos_sect {
  overflow: hidden;
  padding: 40px 0 0;

  @media(min-width: 992px) {
    padding: 57px 0 80px;
  }

  .container {
    position: relative;
  }

  .servicos_carousel {
    overflow: visible;
    padding-bottom: 121px;
    margin-top: 32px;

    @media(min-width: 992px) {
      overflow: hidden;
      padding-bottom: 0;
      margin-top: 40px;
    }

    .swiper-slide {
      height: auto;
    }

    .servicos_card {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;

      .card_body {
        flex: 1;
        position: relative;
        background-color: #39414C;
        padding: 46px 50px 30px;

        &::after {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 6px;
          background: linear-gradient(90deg, #FF5F19 0%, rgba(255, 255, 255, 0.00) 100%);
        }

        .card_title {
          color: white;
          font-family: 'versos', sans-serif;
          font-size: 36px;       
          line-height: normal;
          margin: 24px 0 0;
        }
      }

      .card_footer {
        padding: 25px 50px;
        background-color: $cores-neutras-cinza-2;

        .link {
          color: #FF5F19;
          text-decoration: none;
          font-size: 18px;       
          font-weight: 500;
          line-height: 20px;
          letter-spacing: -0.18px;
          transition: 0.3s ease;

          &:hover {
            color: lighten(#FF5F19, 10%);
          }
        }
      }
    }
  }
}

.artigos_sect {
  overflow: hidden;
  padding: 40px 0 45px;

  @media(min-width: 992px){
    padding: 63px 0 106px;
  }

  .container {
    position: relative;

    .navigation {
      @media(max-width: 991px) {
        display: none;
      }
    }
  }

  .artigos_carousel {
    margin-top: 40px;
    overflow: visible;

    @media(min-width: 992px){
      overflow: hidden;
    }

    .swiper-slide {
      height: auto;
    }

    .acoes_card {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
      text-decoration: none;

      &:hover {
        .image_holder {
          transform: scale(1.1);
        }
      }

      .image_wrapper {
        width: 100%;
        position: relative;
        overflow: hidden;

        &::before {
          content: '';
          display: block;
          padding-bottom: 65%;
        }

        .image_holder {   
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          right: 0;    
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;  
          transition: 0.3s ease;      
        }
      }  

      .card_content {
        display: flex;
        flex-direction: column;
        padding: 16px 0;
        flex: 1;

        .link-padrao-laranja {
          width: fit-content;

          &::after {
            height: 2px;
            bottom: -6px;
          }
        }

        .content {
          flex: 1;
          margin-bottom: 16px;

          .card_title {
            color: $preto;
            font-family: 'versos', sans-serif;
            font-size: 14px;         
            font-weight: 700;
            line-height: 110%;
            margin-bottom: 16px;

            @media(min-width: 992px) {
              font-size: 24px;         
              font-weight: 400;
            }
          }

          .card_text {
            color: $cores-neutras-cinza-7;            
            font-size: 14px;          
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.14px;
            margin-bottom: 0;

            @media(min-width: 992px) {
              font-size: 16px;     
              letter-spacing: -0.16px;
            }
          }
        }
      }
    }
  }
}

.fale-conosco_sect {

  .container {
    position: relative;

    .flutuante_img {
      display: none;
      position: absolute;
      left: 7%;
      bottom: 20%;
      z-index: -1;

      @media(min-width: 992px) {
        display: block;
      }
    }
  }
}

.banner-interna {
  @media (max-width: 545px) {
    background-position-x: -735px;
    background-position-y: -66px;
    background-size: inherit;
  }

  &:before {
    content: none;
  }

  .container {
    @media (max-width: 545px) {
      align-items: center;
    }
  }

  .iconic-banner {
    max-width: 271px;

    @media (min-width: 992px) {
      max-width: initial;
    }
  }
}

.blur {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.31) 22.33%, rgba(255, 255, 255, 0.00) 97.3%);
  backdrop-filter: blur(6px);
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 320px;

  @media (min-width: 545px) {
    width: 50%;
    height: 100%;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.31) 22.33%, rgba(255, 255, 255, 0.00) 97.3%);
  }
}