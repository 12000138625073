.navigation-orange {
    .swiper-button-prev, .swiper-button-next {
        top: initial;
        bottom: 48px;
        width: 48px;
        height: 48px;
        background-color: $laranja;
        color: white;
    
        @media(min-width: 992px) {
          bottom: initial;
          top: 20px;
        }
    
        &::after {
          display: none;
        }
    }
    
    .swiper-button-prev {
        left: 24px;
    
        @media(min-width: 992px) {
            left: initial;
            right: 80px;
        }
    }
    
    .swiper-button-next {
        right: initial;
        left: 80px;
    
        @media(min-width: 992px) {
            left: initial;
            right: 24px;
        }
    }
    
    .swiper-button-disabled {
        background-color: $cinza;
        color: $cores-neutras-cinza-4;
    }
}

.carousel-orange-controls {   

    .swiper-pagination {
        display: none;        
  
        @media(min-width: 992px){
            position: relative;
            margin-top: 24px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
  
        .swiper-pagination-bullet {
          width: 10px;
          height: 10px;
          border-radius: initial;
          background-color: $cinza;
          opacity: 1;
  
          &-active {
            background-color: $laranja;
          }
        }
    }
}

.navigation-purple {
    .swiper-button-prev, .swiper-button-next {
        top: initial;
        bottom: 48px;
        width: 48px;
        height: 48px;
        background-color: $roxo;
        color: white;
    
        @media(min-width: 992px) {
          bottom: initial;
          top: 20px;
        }
    
        &::after {
          display: none;
        }
    }
    
    .swiper-button-prev {
        left: 24px;
    
        @media(min-width: 992px) {
            left: initial;
            right: 80px;
        }
    }
    
    .swiper-button-next {
        right: initial;
        left: 80px;
    
        @media(min-width: 992px) {
            left: initial;
            right: 24px;
        }
    }
    
    .swiper-button-disabled {
        background-color: $cinza;
        color: $cores-neutras-cinza-4;
    }
}

.carousel-purple-controls {   

    .swiper-pagination {
        display: none;        
  
        @media(min-width: 992px){
            position: relative;
            margin-top: 24px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
  
        .swiper-pagination-bullet {
          width: 10px;
          height: 10px;
          border-radius: initial;
          background-color: $cinza;
          opacity: 1;
  
          &-active {
            background-color: $roxo;
          }
        }
    }
}