/* ==========================================================================
   Forms
   ========================================================================== */

.form-label {
   color: $cores-neutras-cinza-7;
   font-size: 16px;   
   font-weight: 500;
   line-height: 20px; 
   letter-spacing: -0.16px;
   margin-bottom: 8px;
}

.form-control, .form-select {
   height: 56px;
   border-radius: 6px;
   border: 1px solid $cinza;
   padding-left: 16px;
   padding-right: 16px;

   &::-webkit-input-placeholder { 
      color:$cinza-b7;
  }
  &:-moz-placeholder { 
     color:$cinza-b7;
     opacity:  1;
  }
  &::-moz-placeholder { 
     color:$cinza-b7;
     opacity:  1;
  }
  &:-ms-input-placeholder { 
     color:$cinza-b7;
  }
  &::-ms-input-placeholder { 
     color:$cinza-b7;
  }
  
  &::placeholder { 
     color:$cinza-b7;
  }

  option {
   color: $preto;
  }
}

.text-error{
   color: $laranja;
   font-size: 14px;
   font-style: normal;
   font-weight: 500;
   line-height: 20px; 
   letter-spacing: -0.14px;
   margin-bottom: 0;
}