.dropdown_header {
    border-radius: 0;
    padding: 24px 16px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
    border: 0;
    margin-top: 12px !important;

    li + li {
        margin-top: 10px;
    }

    .dropdown-item {       
        position: relative;
        padding: 0;
        width: fit-content;
        font-size: 16px;       
        font-weight: 500;
        line-height: 20px;       
        color: $cinza-escuro;
        transition: 0.3s ease;   

        &::after {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            bottom: -4px;
            height: 2px;
            transition: 0.3s ease;
            background: linear-gradient(90deg, $laranja 0%, rgba(255, 255, 255, 0.00) 100%);
        }

        &:hover {
            color: $laranja;

            &::after {
                right: 0;
            }
        }
    }
}