.banner-lubewatch {

    .container {
        @media(min-width: 992px){
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .iconic-banner {
            max-width: 195px;

            @media (min-width: 992px) {
                max-width: 278px;
            }
        }

        .play_btn {
            display: flex;
            align-items: center;
            gap: 11px;
            background-color: transparent;
            outline: none;
            border: 0;
            color: white; 
            transition: 0.3s ease;
            margin: 74px auto 0;

            @media(min-width: 992px){
                margin: 0 120px 0 0;
            }

            &:hover {
                opacity: 0.8;
            }
            
            .text {
                font-size: 16px;         
                font-weight: 500;
                line-height: 20px; 
            }
        }
    }    
}

.modal_iframe {    

    .modal-dialog {
        width: 100%;
        padding: 24px;
        max-width: inherit;

        @media(min-width: 992px){
            max-width: 70vw;
            padding: 0;
        }

        .modal-content {
            position: relative;
            padding-top: 56.25%;
            transform: none;
            background-color: transparent;
            border: 0;

            .btn-close {
                position: absolute;
                right: 0;
                top: -30px;
                opacity: 1;
                transition: 0.3s ease;

                &:hover {
                    opacity: 0.8;
                }
            }

            .modal_video {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
}

.sect_mais-beneficios-lubewatch {
    
    .sect_transformar {
        background-color: #39414C;
        .box-laranja {
            @media(min-width: 992px){
                padding: 24px 25px 25px 47px;
            }
        }

        .texto-transformar {
            padding-bottom: 32px;

            @media (min-width: 992px) {
                margin-bottom: 15px;
            }
    
            @media (min-width: 1200px) {
                max-width: 468px;
            }
        }
    }

    .info-box {
        display: flex;
        align-items: center;
        gap: 11px;
        width: 100%;
        padding: 19px 19px 16px 14px;
        background-color: #19191E;
        color: #FF5F19;
        margin-bottom: 78px;

        @media(min-width: 992px){
            padding: 14px 18px 11px 14px;
            margin-left: 30px;
            max-width: 426px;
            margin-bottom: 0;
        }

        @media (min-width: 1200px) {
            max-width: 468px;
            margin-left: 53px;
        }

        .box_text {        
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: -0.14px;
            margin-bottom: 0;
        }

        .icon {
            width: 24px;
        }
    }

}

.programa-pilares_sect {
    overflow: hidden;
    position: relative;
    padding-top: 48x;

    .titulo {
        text-align: center;
        margin-bottom: 16px;
    }

    .texto {
        color: #797E86;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.14px;
        margin-bottom: 16px;

        @media (min-width: 992px) {
            font-size: 16px;
            letter-spacing: -0.16px;
        }

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    .pilares_wrapper {
        margin-top: 32px;
    }
}

.programa-solucao-completa_sect {
    overflow: hidden;
    padding-top: 48px;

    @media(min-width: 992px){
        padding-top: 83px;
    }

    .titulo {
        margin-bottom: 29px;

        @media(min-width: 992px){
            margin-bottom: 41px;
        }
    }

    .container {
        position: relative;  
        padding-bottom: 135px;   
        
        @media(min-width: 992px){
            padding-bottom: 63px;   
        }
    }

    .swiper {
        overflow: visible;

        @media(min-width: 992px){
            overflow: hidden;
        }

        .swiper-slide {
            background-color: #FF8C5A;
            min-height: 285px;
            width: 283px;
            padding: 28px 52px 42px 30px;
            border: none;

            @media (min-width: 768px) {
                height: 315px;
            }
        
            @media (min-width: 992px) {
                width: 260px;
                padding: 33px 36px 39px 34px;
            }      

            @media (min-width: 1200px) {
                height: 346px;
            }
         
        
            .img-box {
                max-width: 55px;
                margin-bottom: 0;

                @media (min-width: 992px) {
                    max-width: 67px;
                }
            }
            
            .titulo-box {
              color: white;
              font-size: 18px;
              font-weight: 700;
              line-height: 1;
              margin-bottom: 13px;

              @media (min-width: 992px) {
                font-size: 24px;
                margin-bottom: 16px;
              }
            }

            .texto-box {
                color: #19191E;
                font-size: 14px;
                font-weight: 500;
                line-height: 16px;
                letter-spacing: -0.14px;
                margin-bottom: 0;

                @media (min-width: 992px) {
                    line-height: 20px;
                }
            }
          }
    }
}

.sect_portal-lubewatch {
    .sect_transformar {
        background-color: #39414C;
        padding: 56px 0 73px;

        @media (min-width: 992px) {
            height: 630px;
            padding: 0;
        }

        .grafismo-laranja {      
            top: inherit;
            bottom: 0;
            height: 339px;
            background-position: center;
            background-image: url('../img/grafismo-portal-mobile.png');

            @media (min-width: 992px) {
              height: 630px;
              background-image: url('../img/grafismo-portal.png');
              background-size: cover;
              right: 0;
              left: inherit;
            }
        }
    }

    .img-computador {
        position: relative;
        z-index: 10;
        margin-top: 64px;

        @media (min-width: 992px) {
            margin-top: 0;
        }
    }

    .titulo {
        color: #FFF;
        font-size: 36px;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 24px;
    }

    .texto {
        color: #FFF;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.16px;
        margin-bottom: 24px;
        max-width: 588px;
    }

    .btn-link {
        width: max-content;
        font-family: 'versos';
        font-size: 16px;
        font-weight: 400;
        color: $laranja;
        text-decoration: none;
        display: inline-block;
        margin-bottom: 0;
        transition: all ease-in-out .3s;
        position: relative;
        cursor: pointer;
    
        &:hover {
            color: #CD3C00;
        }
    
        &::after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            background: linear-gradient(90deg, #FF5F19 0%, rgba(255, 255, 255, 0.00) 100%);
            width: 100%;
            height: 2px;
            display: inline-block;
        }
    }
}

.programa-performance {
    background-position: bottom;
    margin-bottom: 25px;

    @media (min-width: 992px) {
        background-position: left;
        margin-bottom: 86px;
    }

    .box-image {
        display: flex;
        justify-content: center;
        order: 2; 

        @media (min-width: 992px) {
            order: 1;
        }
    }

    .box-texto {
        order: 1; 

        @media (min-width: 992px) {
            order: 2;
        }
    }
}